import Vue from 'vue';
import Router from 'vue-router';

import businessRoutes from '@/core/routers/business.route';
import fastSyncRoutes from '@/core/routers/fast-sync.route';

// const lazyLoad = (view) =>{
//   return () => import(`@/views/pages/${view}.vue`);
// }

// layout
const Layout = () => import('@/view/layout/Layout');
// route level code-splitting
// this generates a separate chunk (dashboard.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ '@/view/pages/Dashboard.vue');

// stores
const Store = () =>
  import(/* webpackChunkName: "stores" */ '@/view/pages/stores/Store.vue');
const ListStore = () =>
  import(/* webpackChunkName: "stores" */ '@/view/pages/stores/ListStore.vue');
const AddStore = () =>
  import(
    /* webpackChunkName: "add-store" */ '@/view/pages/stores/AddStore.vue'
  );
const EditStore = () =>
  import(
    /* webpackChunkName: "edit-store" */ '@/view/pages/stores/EditStore.vue'
  );

// order-source
const OrderSource = () => import('@/view/pages/order-source/OrderSource.vue');
const ListOrderSource = () =>
  import('@/view/pages/order-source/ListOrderSource.vue');

// customers
const Customer = () => import('@/view/pages/customers/Customer.vue');
const ListCustomer = () => import('@/view/pages/customers/ListCustomer.vue');
const AddCustomer = () => import('@/view/pages/customers/AddCustomer.vue');
const UpdateCustomer = () =>
  import('@/view/pages/customers/UpdateCustomer.vue');

const CustomerType = () => import('@/view/pages/customer-type/CustomerType.vue');
const ListCustomerType = () =>
  import('@/view/pages/customer-type/ListCustomerType.vue');
const AddCustomerType = () =>
  import('@/view/pages/customer-type/AddCustomerType.vue');
const UpdateCustomerType = () =>
  import('@/view/pages/customer-type/UpdateCustomerType.vue');

// categories
const Categories = () => import('@/view/pages/categories/Categories.vue');
const ListCategory = () => import('@/view/pages/categories/ListCategory.vue');
const AddCategory = () => import('@/view/pages/categories/AddCategory.vue');
const UpdateCategory = () =>
  import('@/view/pages/categories/UpdateCategory.vue');

// suppliers
const Supplier = () => import('@/view/pages/suppliers/Supplier.vue');
const ListSupplier = () => import('@/view/pages/suppliers/ListSupplier.vue');

// products
const Product = () => import('@/view/pages/products/Product.vue');
const ListProducts = () => import('@/view/pages/products/ListProducts.vue');
const AddProduct = () => import('@/view/pages/products/AddProduct.vue');
const ImportProduct = () => import('@/view/pages/products/ImportProduct.vue');
const ImportProductCombo = () =>
  import('@/view/pages/products/ImportProductCombo.vue');
const UpdateProduct = () => import('@/view/pages/products/UpdateProduct.vue');
const InventoryDetail4IMEI = () =>
  import('@/view/pages/products/InventoryDetail4IMEI.vue');
const InventoryDetail = () =>
  import('@/view/pages/products/InventoryDetail.vue');
const UpdateID4Imei = () => import('@/view/pages/products/UpdateID4Imei.vue');
const PrintBarcode = () => import('@/view/pages/products/PrintBarcode.vue');
const PrintProductQRCode = () =>
  import('@/view/pages/products/PrintProductQRCode.vue');
const ProductPrint = () => import('@/view/pages/products/ProductPrint.vue');

// properties
const Property = () => import('@/view/pages/properties/Property.vue');
const ListProperty = () => import('@/view/pages/properties/ListProperty.vue');
const AddProperty = () => import('@/view/pages/properties/AddProperty.vue');
const UpdateProperty = () =>
  import('@/view/pages/properties/UpdateProperty.vue');

// permissions
const Permission = () => import('@/view/pages/permissions/Permission.vue');
const ListPermission = () =>
  import('@/view/pages/permissions/ListPermission.vue');
const UpdatePermission = () =>
  import('@/view/pages/permissions/UpdatePermission.vue');
const AddPermission = () =>
  import('@/view/pages/permissions/AddPermission.vue');

// users
const User = () => import('@/view/pages/users/User.vue');
const ListUser = () => import('@/view/pages/users/ListUser.vue');
const AddUser = () => import('@/view/pages/users/UpsertUser.vue');
const UpdateUser = () => import('@/view/pages/users/UpsertUser.vue');

// employees
const Employee = () => import('@/view/pages/employees/Employee.vue');
const ListEmployee = () => import('@/view/pages/employees/ListEmployee.vue');
const AddEmployee = () => import('@/view/pages/employees/AddEmployee.vue');
const UpdateEmployee = () =>
  import('@/view/pages/employees/UpdateEmployee.vue');
const ImportEmployee = () =>
  import('@/view/pages/employees/ImportEmployee.vue');
const UpsertEmployee = () =>
  import('@/view/pages/employees/UpsertEmployee.vue');

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: Layout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard,
        },
        {
          path: '/stores',
          // name: 'stores',
          component: Store,
          children: [
            {
              path: '/',
              name: 'list-store',
              component: ListStore,
              meta: {
                title: 'Danh sách cửa hàng - DDV',
              },
            },
            {
              path: 'add-store',
              name: 'add-store',
              component: AddStore,
              meta: {
                title: 'Thêm mới cửa hàng - DDV',
              },
            },
            {
              path: 'edit-store',
              name: 'edit-store',
              component: EditStore,
              meta: {
                title: 'Cập nhật cửa hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/order-source',
          // name: 'order-source',
          component: OrderSource,
          children: [
            {
              path: '/',
              name: 'list-order-source',
              component: ListOrderSource,
              meta: {
                title: 'Danh sách nguồn đơn hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/customers',
          // name: 'customers',
          component: Customer,
          children: [
            {
              path: '/',
              name: 'list-customer',
              component: ListCustomer,
              meta: {
                title: 'Danh sách khách hàng - DDV',
              },
            },
            {
              path: 'add-customer',
              name: 'add-customer',
              component: AddCustomer,
              meta: {
                title: 'Thêm mới khách hàng - DDV',
              },
            },
            {
              path: 'update-customer',
              name: 'update-customer',
              component: UpdateCustomer,
              meta: {
                title: 'Cập nhật khách hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/customer-type',
          // name: 'customers',
          component: CustomerType,
          children: [
            {
              path: '/',
              name: 'list-customer-type',
              component: ListCustomerType,
              meta: {
                title: 'Danh sách khách hàng - DDV',
              },
            },
            {
              path: 'add-customer-type',
              name: 'add-customer-type',
              component: AddCustomerType,
              meta: {
                title: 'Thêm mới khách hàng - DDV',
              },
            },
            {
              path: 'update-customer-type',
              name: 'update-customer-type',
              component: UpdateCustomerType,
              meta: {
                title: 'Cập nhật khách hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/categories',
          // name: 'categories',
          component: Categories,
          children: [
            {
              path: '/',
              name: 'list-category',
              component: ListCategory,
              meta: {
                title: 'Danh mục sản phẩm - DDV',
              },
            },
            {
              path: 'add-category',
              name: 'add-category',
              component: AddCategory,
              meta: {
                title: 'Thêm mới danh mục sản phẩm  - DDV',
              },
            },
            {
              path: 'update-category',
              name: 'update-category',
              component: UpdateCategory,
              meta: {
                title: 'Cập nhật danh mục sản phẩm  - DDV',
              },
            },
          ],
        },
        {
          path: '/suppliers',
          // name: 'suppliers',
          component: Supplier,
          children: [
            {
              path: '/',
              name: 'list-suppliers',
              component: ListSupplier,
              meta: {
                title: 'Danh sách nhà cung cấp - DDV',
              },
            },
          ],
        },
        {
          path: '/products',
          component: Product,
          children: [
            {
              path: '/',
              name: 'list-products',
              component: ListProducts,
              meta: {
                title: 'Danh sách sản phẩm - DDV',
              },
            },
            {
              path: 'add-product',
              name: 'addProduct',
              component: AddProduct,
              meta: {
                title: 'Thêm mới sản phẩm - DDV',
              },
            },
            {
              path: 'import-product',
              name: 'import-product',
              component: ImportProduct,
              meta: {
                title: 'Nhập sản phẩm - DDV',
              },
            },
            {
              path: 'import-product-combo',
              name: 'import-product-combo',
              component: ImportProductCombo,
              meta: {
                title: 'Nhập sản phẩm combo - DDV',
              },
            },
            {
              path: 'update-product',
              name: 'update-product',
              component: UpdateProduct,
              meta: {
                title: 'Cập nhật sản phẩm - DDV',
              },
            },
            {
              path: 'inventory-detail-imei',
              name: 'inventory-detail-imei',
              component: InventoryDetail4IMEI,
              meta: {
                title: 'Chi tiết tồn kho sản phẩm IMEI - DDV',
              },
            },
            {
              path: 'inventory-detail',
              name: 'inventory-detail',
              component: InventoryDetail,
              meta: {
                title: 'Chi tiết tồn kho sản phẩm - DDV',
              },
            },
            {
              path: 'update-inventory-detail',
              name: 'update-inventory-detail',
              component: UpdateID4Imei,
              meta: {
                title: 'Chi tiết IMEI - DDV',
              },
            },
            {
              path: 'print-barcode',
              name: 'print-barcode',
              component: PrintBarcode,
              meta: {
                title: 'In mã vạch sản phẩm - DDV',
              },
              props: true,
            },
            {
              path: 'print-price-product',
              name: 'print-price-product',
              component: () =>
                import('@/view/pages/products/PrintPriceProduct.vue'),
              meta: {
                title: 'In bảng giá sản phẩm - DDV',
              },
              props: true,
            },
            {
              path: 'print-product',
              name: 'print-product',
              component: ProductPrint,
              meta: {
                title: 'In sản phẩm - DDV',
              },
            },
            {
              path: 'print-product-tem',
              name: 'print-product-tem',
              component: () => import('@/view/pages/products/PrintTem.vue'),
              meta: {
                title: 'In tem phụ sản phẩm - DDV',
              },
            },
            {
              path: 'print-product-qrcode',
              name: 'print-product-qrcode',
              component: PrintProductQRCode,
              meta: {
                title: 'In mã sản phẩm - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/permissions',
          // name: 'permissions',
          component: Permission,
          children: [
            {
              path: '/',
              name: 'list-permission',
              component: ListPermission,
              meta: {
                title: 'Danh sách phân quyền - DDV',
              },
            },
            {
              path: 'add-permission',
              name: 'add-permission',
              component: AddPermission,
              meta: {
                title: 'Thêm mới phân quyền - DDV',
              },
            },
            {
              path: 'update-permission',
              name: 'update-permission',
              component: UpdatePermission,
              meta: {
                title: 'Cập nhật phân quyền - DDV',
              },
            },
          ],
        },
        {
          path: '/users',
          // name: 'users',
          component: User,
          children: [
            {
              path: '/',
              name: 'list-user',
              component: ListUser,
              meta: {
                title: 'Danh sách tài khoản - DDV',
              },
            },
            {
              path: 'add-user',
              name: 'add-user',
              component: AddUser,
              meta: {
                title: 'Thêm mới tài khoản - DDV',
              },
            },
            {
              path: 'update-user',
              name: 'update-user',
              component: UpdateUser,
              meta: {
                title: 'Cập nhật tài khoản - DDV',
              },
            },
          ],
        },
        {
          path: '/shiftWorkConfig',
          component: () =>
            import('@/view/pages/shiftWorkConfig/ShiftWorkConfig.vue'),
          children: [
            {
              path: '/',
              name: 'list-shiftWork-config',
              component: () =>
                import('@/view/pages/shiftWorkConfig/ListShiftWorkCongfig.vue'),
              meta: {
                title: 'Danh sách cấu hình ca làm - DDV',
              },
            },
          ],
        },
        {
          path: '/employees',
          // name: 'employees',
          component: Employee,
          children: [
            {
              path: '/',
              name: 'list-employee',
              component: ListEmployee,
              meta: {
                title: 'Danh sách nhân sự - DDV',
              },
            },
            {
              path: 'add-employee',
              name: 'add-employee',
              component: AddEmployee,
              meta: {
                title: 'Thêm mới nhân sự - DDV',
              },
            },
            {
              path: 'update-employee',
              name: 'update-employee',
              component: UpdateEmployee,
              meta: {
                title: 'Cập nhật nhân sự - DDV',
              },
            },
            {
              path: 'import-employee',
              name: 'import-employee',
              component: ImportEmployee,
              meta: {
                title: 'Nhập nhân sự - DDV',
              },
            },
            {
              path: 'upsert-employee',
              name: 'upsert-employee',
              component: UpsertEmployee,
              meta: {
                title: 'Thông tin nhân sự - DDV',
              },
            },
          ],
        },
        {
          path: '/properties',
          // name: 'properties',
          component: Property,
          children: [
            {
              path: '/',
              name: 'list-property',
              component: ListProperty,
              meta: {
                title: 'Danh sách thuộc tính sản phẩm - DDV',
              },
            },
            {
              path: 'add-property',
              name: 'add-property',
              component: AddProperty,
              meta: {
                title: 'Thêm mới thuộc tính sản phẩm - DDV',
              },
            },
            {
              path: 'update-property',
              name: 'update-property',
              component: UpdateProperty,
              meta: {
                title: 'Cập nhật thuộc tính sản phẩm - DDV',
              },
            },
          ],
        },
        {
          path: '/providers',
          // name: 'providers',
          component: () => import('@/view/pages/providers/Provider.vue'),
          children: [
            {
              path: '/',
              name: 'list-provider',
              component: () =>
                import('@/view/pages/providers/ListProvider.vue'),
              meta: {
                title: 'Danh sách nhà cung cấp - DDV',
              },
            },
            {
              path: 'add-provider',
              name: 'add-provider',
              component: () => import('@/view/pages/providers/AddProvider.vue'),
              meta: {
                title: 'Thêm mới nhà cung cấp - DDV',
              },
            },
            {
              path: 'update-provider',
              name: 'update-provider',
              component: () =>
                import('@/view/pages/providers/UpdateProvider.vue'),
              meta: {
                title: 'Cập nhật nhà cung cấp - DDV',
              },
            },
          ],
        },
        {
          path: '/brands',
          // name: 'brands',
          component: () => import('@/view/pages/brands/Brand.vue'),
          children: [
            {
              path: '/',
              name: 'list-brand',
              component: () => import('@/view/pages/brands/ListBrand.vue'),
              meta: {
                title: 'Danh sách thương hiệu - DDV',
              },
            },
          ],
        },
        {
          path: '/stocks',
          // name: 'stocks',
          component: () => import('@/view/pages/stocks/Stock.vue'),
          children: [
            {
              path: '/',
              name: 'list-stock',
              component: () => import('@/view/pages/stocks/ListStock.vue'),
              meta: {
                title: 'Danh sách phiếu nhập kho - DDV',
              },
            },
            {
              path: 'add-stock',
              name: 'add-stock',
              component: () => import('@/view/pages/stocks/AddStock.vue'),
              meta: {
                title: 'Thêm mới phiếu nhập kho - DDV',
              },
            },
            {
              path: 'update-stock',
              name: 'update-stock',
              component: () => import('@/view/pages/stocks/UpdateStock.vue'),
              meta: {
                title: 'Cập nhật phiếu nhập kho - DDV',
              },
            },
            {
              path: 'print-import-stockslip',
              name: 'print-import-stockslip',
              component: () =>
                import('@/view/pages/print-stockslips/PrintImportExport.vue'),
              meta: {
                title: 'In phiếu nhập kho - DDV',
              },
            },
          ],
        },
        {
          path: '/export-stocks',
          // name: 'export-stocks',
          component: () => import('@/view/pages/stocks/ExportStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-export-stock',
              component: () => import('@/view/pages/stocks/ListEStock.vue'),
              meta: {
                title: 'Danh sách phiếu xuất kho - DDV',
              },
            },
            {
              path: 'add-export-stock',
              name: 'add-export-stock',
              component: () => import('@/view/pages/stocks/AddEStock.vue'),
              meta: {
                title: 'Thêm mới phiếu xuất kho - DDV',
              },
            },
            {
              path: 'update-export-stock',
              name: 'update-export-stock',
              component: () => import('@/view/pages/stocks/UpdateEStock.vue'),
              meta: {
                title: 'Cập nhật phiếu xuất kho - DDV',
              },
            },
            {
              path: 'print-export-stockslip',
              name: 'print-export-stockslip',
              component: () =>
                import('@/view/pages/print-stockslips/PrintImportExport.vue'),
              meta: {
                title: 'In phiếu xuất kho - DDV',
              },
            },
          ],
        },
        {
          path: '/transfer-stocks',
          // name: 'transfer-stocks',
          component: () => import('@/view/pages/stocks/TransferStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-transfer-stock',
              component: () => import('@/view/pages/stocks/ListTStock.vue'),
              meta: {
                title: 'Danh sách phiếu chuyển kho - DDV',
              },
            },
            {
              path: 'add-transfer-stock',
              name: 'add-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/AddTransferStock.vue'),
              meta: {
                title: 'Thêm mới phiếu chuyển kho - DDV',
              },
            },
            {
              path: 'update-transfer-stock',
              name: 'update-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/UpdateTransferStock.vue'),
              meta: {
                title: 'Cập nhật phiếu chuyển kho - DDV',
              },
            },
            {
              path: 'print-transfer-stockslip',
              name: 'print-transfer-stockslip',
              component: () =>
                import('@/view/pages/print-stockslips/PrintTransfer.vue'),
              meta: {
                title: 'In phiếu chuyển kho - DDV',
              },
            },
            {
              path: 'view-detail-parent-transfer-stock',
              name: 'view-detail-parent-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/DetailParentStock.vue'),
              meta: {
                title: 'Cập nhật phiếu chuyển kho - DDV',
              },
            },
          ],
        },
        {
          path: '/transfer-stock-deleted',
          component: () => import('@/view/pages/stocks/TStockSlipDeleted.vue'),
          children: [
            {
              path: '/',
              name: 'list-transfer-stock-deleted',
              component: () =>
                import('@/view/pages/stocks/ListTStockSlipDeleted.vue'),
              meta: {
                title: 'Danh sách phiếu chuyển kho đã xóa - DDV',
              },
            },
            {
              path: 'detail-transfer-stock-deleted',
              name: 'detail-transfer-stock-deleted',
              component: () =>
                import('@/view/pages/stocks/DetailTStockSlipDeleted.vue'),
              meta: {
                title: 'Thông tin phiếu chuyển kho đã xóa - DDV',
              },
            },
          ],
        },
        {
          path: '/complete-warranty-stock',
          component: () =>
            import('@/view/pages/stocks/CompleteWarrantyStock.vue'),
          children: [
            {
              path: '/',
              name: 'complete-warranty-stock',
              component: () =>
                import('@/view/pages/stocks/ListCompleteWarrantyStock.vue'),
              meta: {
                title: 'Danh sách phiếu hoàn tất bảo hành - DDV',
              },
            },
            {
              path: 'upsert-complete-warranty-stock',
              name: 'upsert-complete-warranty-stock',
              component: () =>
                import('@/view/pages/stocks/UpsertCompleteWarrantyStock.vue'),
              meta: {
                title: 'Thông tin phiếu hoàn tất bảo hành - DDV',
              },
            },
          ],
        },
        {
          path: '/shipment',
          component: () => import('@/view/pages/shipment/Shipment.vue'),
          children: [
            {
              path: '/',
              name: 'list-shipment',
              component: () => import('@/view/pages/shipment/ListShipment.vue'),
              meta: {
                title: 'Danh sách vận chuyển - DDV',
              },
            },
            {
              path: 'upsert-shipment',
              name: 'upsert-shipment',
              component: () =>
                import('@/view/pages/shipment/UpsertShipment.vue'),
              meta: {
                title: 'Thông tin vận chuyển - DDV',
              },
            },
          ],
        },
        {
          path: '/orders',
          // name: 'orders',
          component: () => import('@/view/pages/orders/Order.vue'),
          children: [
            {
              path: '/',
              name: 'list-order',
              component: () => import('@/view/pages/orders/ListOrder.vue'),
              meta: {
                title: 'Danh sách đơn hàng - DDV',
              },
            },
            {
              path: 'edit-order/:id',
              name: 'edit-order',
              component: () => import('@/view/pages/orders/UpsertOrder.vue'),
              meta: {
                title: 'Chỉnh sửa đơn hàng - DDV',
              },
              props: true,
            },
            {
              path: 'add-order',
              name: 'add-order',
              component: () => import('@/view/pages/orders/UpsertOrder.vue'),
              meta: {
                title: 'Thêm mới đơn hàng - DDV',
              },
            },
            {
              path: 'update-order',
              name: 'update-order',
              component: () => import('@/view/pages/orders/UpdateOrder.vue'),
              meta: {
                title: 'Cập nhật đơn hàng - DDV',
              },
            },
            {
              path: 'print-order',
              name: 'print-order',
              component: () => import('@/view/pages/orders/PrintOrderV1.vue'),
              meta: {
                title: 'In đơn hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/pre-order',
          component: () => import('@/view/pages/orders/Order.vue'),
          children: [
            {
              path: '/',
              name: 'list-pre-order',
              component: () => import('@/view/pages/pre-orders/PreOrder.vue'),
              meta: {
                title: 'Danh sách đơn hàng đặt trước - DDV',
              },
            },
          ],
        },
        {
          path: '/pre-order-form',
          component: () =>
            import('@/view/pages/pre-order-forms/PreOrderForm.vue'),
          children: [
            {
              path: '/',
              component: () =>
                import('@/view/pages/pre-order-forms/PreOrderFormList.vue'),
              meta: {
                title: 'Chương trình đơn hàng đặt trước - DDV',
              },
            },
          ],
        },
        {
          path: '/transfer-store-forms',
          component: () =>
            import('@/view/pages/transfer-store-forms/TransferStoreForm.vue'),
          children: [
            {
              path: '/',
              component: () =>
                import(
                  '@/view/pages/transfer-store-forms/ListTransferStoreForms.vue'
                ),
              name: 'list-transfer-store-forms',
              meta: {
                title: 'Phiếu chuyển cửa hàng cho nhân viên nội bộ - DDV',
              },
            },
            {
              path: 'add-form',
              name: 'add-transfer-store-form',
              component: () =>
                import(
                  '@/view/pages/transfer-store-forms/AddTransferStoreForm.vue'
                ),
              meta: {
                title: 'Tạo mới đơn chuyển cửa hàng - DDV',
              },
            },
            {
              path: 'edit-form',
              name: 'edit-transfer-store-form',
              component: () =>
                import(
                  '@/view/pages/transfer-store-forms/EditTransferStoreForm.vue'
                ),
              meta: {
                title: 'Xem chi tiết đơn chuyển cửa hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/shift-work',
          component: () => import('@/view/pages/shift-work/ShiftWork.vue'),
          children: [
            {
              path: '/',
              name: 'list-shiftWork',
              component: () =>
                import('@/view/pages/shift-work/ListShiftWork.vue'),
              meta: {
                title: 'Danh sách ca làm việc - DDV',
              },
            },
          ],
        },
        {
          path: '/cms-sync',
          component: () => import('@/view/pages/cms-sync-monitor/SyncForm.vue'),
          children: [
            {
              path: '/',
              component: () =>
                import('@/view/pages/cms-sync-monitor/SyncList.vue'),
              meta: {
                title: 'Trạng thái đồng bộ',
              },
            },
            {
              path: 'config',
              component: () =>
                import('@/view/pages/cms-sync-monitor/SyncConfig.vue'),
              meta: {
                title: 'Cấu hình đồng bộ',
              },
            },
          ],
        },
        {
          path: '/cms-sync-config',
          component: () => import('@/view/pages/cms-sync-monitor/SyncForm.vue'),
          children: [
            {
              path: '/',
              component: () =>
                import('@/view/pages/cms-sync-monitor/SyncConfig.vue'),
              meta: {
                title: 'Cấu hình đồng bộ CMS',
              },
            },
          ],
        },
        {
          path: '/installment-program',
          component: () =>
            import('@/view/pages/installment-programs/InstallmentProgram.vue'),
          children: [
            {
              path: '/',
              name: 'list-installment-program',
              component: () =>
                import(
                  '@/view/pages/installment-programs/InstallmentProgramList.vue'
                ),
              meta: {
                title: 'Cấu hình chương trình trả góp - DDV',
              },
            },
            {
              path: 'add-installment-program',
              name: 'add-installment-program',
              component: () =>
                import(
                  '@/view/pages/installment-programs/UpsertInstallmentProgram.vue'
                ),
              meta: {
                title: 'Tạo chương trình trả góp - DDV',
              },
            },
            {
              path: 'edit-installment-program/:id',
              name: 'edit-installment-program',
              component: () =>
                import(
                  '@/view/pages/installment-programs/UpsertInstallmentProgram.vue'
                ),
              meta: {
                title: 'Cập nhật chương trình trả góp - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/product-require-stocks',
          component: () => import('@/view/pages/stocks/RStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-require',
              component: () =>
                import('@/view/pages/stocks/ListProductRequire.vue'),
              meta: {
                title: 'Danh sách sản phẩm XNK - DDV',
              },
            },
          ],
        },
        {
          path: '/product-require-stock-to-nhanh',
          component: () => import('@/view/pages/stocks/TransferToNhanh.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-require-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/ListProductRequireToNhanh.vue'),
              meta: {
                title: 'Danh sách sản phẩm XNK sang hệ thống cũ - DDV',
              },
            },
          ],
        },
        {
          path: '/require-stocks',
          component: () => import('@/view/pages/stocks/RStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-require-stock',
              component: () =>
                import('@/view/pages/stocks/ListRequireStock.vue'),
              meta: {
                title: 'Danh sách phiếu yêu cầu chuyển kho - DDV',
              },
            },
            {
              path: '/require-warranty-stocks',
              name: 'require-warranty-stocks',
              component: () =>
                import('@/view/pages/stocks/ListRequireWarrantyStock.vue'),
              meta: {
                title: 'Danh sách phiếu yêu cầu chuyển kho BH - DDV',
              },
            },
            {
              path: 'approve-transfer-stock',
              name: 'approve-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/ApproveTransferStock.vue'),
              meta: {
                title: 'Duyệt phiếu yêu cầu chuyển kho - DDV',
              },
            },
            {
              path: 'approve-transfer-warranty-stock',
              name: 'approve-transfer-warranty-stock',
              component: () =>
                import('@/view/pages/stocks/ApproveTransferWarrantyStock.vue'),
              meta: {
                title: 'Duyệt phiếu yêu cầu chuyển kho bảo hành - DDV',
              },
            },
            {
              path: 'confirm-transfer-stock',
              name: 'confirm-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/ConfirmTransferStock.vue'),
              meta: {
                title: 'Xác nhận phiếu yêu cầu chuyển kho - DDV',
              },
            },
            {
              path: 'print-require-stockslip',
              name: 'print-require-stockslip',
              component: () =>
                import('@/view/pages/print-stockslips/PrintTransfer.vue'),
              meta: {
                title: 'In phiếu chuyển kho - DDV',
              },
            },
          ],
        },
        {
          path: '/old-imeis',
          component: () => import('@/view/pages/old-imeis/FindImei.vue'),
          name: 'list-old-imeis',
        },
        {
          path: '/imeis',
          component: () => import('@/view/pages/imeis/Imei.vue'),
          children: [
            {
              path: '/',
              name: 'list-imeis',
              component: () => import('@/view/pages/imeis/ListImei.vue'),
              meta: {
                title: 'Danh sách IMEI - DDV',
              },
            },
            {
              path: 'update-imei',
              name: 'update-imei',
              component: () =>
                import('@/view/pages/products/UpdateID4Imei.vue'),
              meta: {
                title: 'Cập nhật IMEI - DDV',
              },
            },
            {
              path: 'change-product-imei',
              name: 'change-product-imei',
              component: () => import('@/view/pages/imeis/ChangeProduct.vue'),
              meta: {
                title: 'Đổi sản phẩm IMEI - DDV',
              },
            },
            {
              path: 'import-change-product-imei',
              name: 'import-change-product-imei',
              component: () =>
                import('@/view/pages/imeis/ImportChangeProduct.vue'),
              meta: {
                title: 'Import đổi sản phẩm IMEI - DDV',
              },
            },
            {
              path: 'active-imei',
              name: 'active-imei',
              component: () => import('@/view/pages/imeis/ImportActive.vue'),
              meta: {
                title: 'Kích hoạt IMEI - DDV',
              },
            },
          ],
        },
        {
          path: '/accounting',
          component: () => import('@/view/pages/accountant/Accountant.vue'),
          children: [
            {
              path: 'accounts',
              name: 'list-accountant',
              component: () =>
                import('@/view/pages/accountant/ListAccountant.vue'),
              meta: {
                title: 'Danh sách tài khoản kế toán - DDV',
              },
            },
            {
              path: 'accounts/add',
              name: 'add-accountant',
              component: () =>
                import('@/view/pages/accountant/AddAccountant.vue'),
              meta: {
                title: 'Thêm mới tài khoản kế toán - DDV',
              },
            },
            {
              path: 'accounts/update',
              name: 'update-accountant',
              component: () =>
                import('@/view/pages/accountant/UpdateAccountant.vue'),
              meta: {
                title: 'Cập nhật tài khoản kế toán - DDV',
              },
            },
            // THU CHI TIỀN MẶT, NGÂN HÀNG
            {
              path: 'transactions',
              name: 'accountant-transactions',
              component: () =>
                import('@/view/pages/transaction/Transaction.vue'),
              children: [
                {
                  path: ':mode',
                  name: 'list-transaction',
                  component: () =>
                    import('@/view/pages/transaction/ListTransaction.vue'),
                  props: true,
                  meta: {
                    title: 'Phiếu thu chi - DDV',
                  },
                },
                {
                  path: ':mode/:action/:id',
                  name: 'transaction',
                  component: () =>
                    import('@/view/pages/transaction/AddTransaction.vue'),
                  props: true,
                },
              ],
            },
            {
              path: 'debts',
              name: 'accountant-debt',
              component: () => import('@/view/pages/debt/Debt.vue'),
              children: [
                {
                  path: 'items',
                  name: 'list-debt-items',
                  component: () => import('@/view/pages/debt/Items.vue'),
                  meta: {
                    title: 'Công nợ trả góp hóa đơn theo ngày - DDV',
                  },
                },
                {
                  path: 'supplierduedate',
                  name: 'list-debt-supplierduedate',
                  component: () =>
                    import('@/view/pages/debt/ProviderItems.vue'),
                  meta: {
                    title: 'Hạn thanh toán nhà cung cấp - DDV',
                  },
                },
                {
                  path: 'installments',
                  name: 'list-debt-installments',
                  component: () => import('@/view/pages/debt/Installment.vue'),
                  meta: {
                    title: 'Công nợ dịch vụ trả góp - DDV',
                  },
                },
                {
                  path: 'credits',
                  name: 'list-debt-credits',
                  component: () => import('@/view/pages/debt/CreditItems.vue'),
                  meta: {
                    title: 'Công nợ quẹt thẻ hóa đơn theo ngày - DDV',
                  },
                },
                {
                  path: 'providers',
                  name: 'list-debt-providers',
                  component: () => import('@/view/pages/debt/Provider.vue'),
                  meta: {
                    title: 'Công nợ nhà cung cấp  - DDV',
                  },
                },
                {
                  path: 'credit-orders',
                  name: 'list-debt-credit-order',
                  component: () => import('@/view/pages/debt/CreditOrder.vue'),
                  meta: {
                    title: 'Công nợ quẹt thẻ đơn hàng - DDV',
                  },
                },
                {
                  path: 'installment-orders',
                  name: 'list-debt-installment-order',
                  component: () =>
                    import('@/view/pages/debt/InstallmentOrder.vue'),
                  meta: {
                    title: 'Công nợ quẹt thẻ đơn hàng - DDV',
                  },
                },
                {
                  path: 'ship-fee-orders',
                  name: 'list-ship-fee-orders',
                  component: () => import('@/view/pages/debt/ShipFeeOrder.vue'),
                  meta: {
                    title: 'Công nợ phí ship đơn hàng - DDV',
                  },
                },
                {
                  path: 'purchase-order',
                  name: 'list-debt-purchase-order',
                  component: () =>
                    import('@/view/pages/debt/PurchaseOrder.vue'),
                  meta: {
                    title: 'Công nợ nhập hàng - DDV',
                  },
                },
              ],
            },
            // DỊCH VỤ TRẢ GÓP
            {
              path: 'installments',
              component: () =>
                import('@/view/pages/installment/Installment.vue'),
              children: [
                {
                  path: '/',
                  name: 'list-installment',
                  component: () =>
                    import('@/view/pages/installment/ListInstallment.vue'),
                  meta: {
                    title: 'Danh sách dịch vụ trả góp - DDV',
                  },
                },
                {
                  path: 'add',
                  name: 'add-installment',
                  component: () =>
                    import('@/view/pages/installment/AddInstallment.vue'),
                  meta: {
                    title: 'Thêm mới dịch vụ trả góp - DDV',
                  },
                },
                {
                  path: 'update',
                  name: 'update-installment',
                  component: () =>
                    import('@/view/pages/installment/UpdateInstallment.vue'),
                  meta: {
                    title: 'Cập nhật dịch vụ trả góp - DDV',
                  },
                },
              ],
            },
            {
              path: 'import-excel',
              component: () =>
                import('@/view/pages/import-excel/ImportExcel.vue'),
              children: [
                {
                  path: 'debt/:mode',
                  name: 'import-debt',
                  component: () => import('@/view/pages/import-excel/Debt.vue'),
                  meta: {
                    title: 'Nhập excel công nợ',
                  },
                },
              ],
            },
          ],
        },
        // Nhân sự
        {
          path: '/human-resources',
          component: () =>
            import('@/view/pages/human-resources/HumanResources.vue'),
          children: [
            {
              path: '/',
              name: 'list-payroll',
              component: () => import('@/view/pages/human-resources/List.vue'),
              meta: {
                title: 'Danh sách bảng lương - DDV',
              },
            },
            {
              path: 'import-payroll',
              name: 'import-payroll',
              component: () => import('@/view/pages/human-resources/Add.vue'),
              meta: {
                title: 'Nhập bảng lương - DDV',
              },
            },
          ],
        },
        {
          path: '/employee-debt',
          component: () => import('@/view/pages/employee-debt/EmployeeDebt.vue'),
          children: [
            {
              path: '/',
              name: 'employee-debt-list',
              component: () =>
                import('@/view/pages/employee-debt/EmployeeDebtList.vue'),
              meta: {
                title: 'Danh sách công nợ nhân viên - DDV',
              },
            },
            {
              path: 'add',
              name: 'employee-debt-add',
              component: () =>
                import('@/view/pages/employee-debt/EmployeeDebtUpsert.vue'),
              meta: {
                title: 'Tạo công nợ nhân viên - DDV',
              },
            },
            {
              path: 'update/:id',
              name: 'employee-debt-update',
              component: () =>
                import('@/view/pages/employee-debt/EmployeeDebtUpsert.vue'),
              meta: {
                title: 'Cập nhật công nợ nhân viên - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/bills',
          component: () => import('@/view/pages/bills/Bill.vue'),
          children: [
            {
              path: '/',
              name: 'list-bill',
              component: () => import('@/view/pages/bills/ListBill.vue'),
              meta: {
                title: 'Danh sách hóa đơn bán lẻ - DDV',
              },
              props: { billType: 1 },
            },
            {
              path: 'add-bill',
              name: 'add-bill',
              component: () => import('@/view/pages/bills/BillInfo.vue'),
              meta: {
                title: 'Thêm mới hóa đơn bán lẻ - DDV',
              },
            },
            {
              path: 'update-bill',
              name: 'update-bill',
              component: () => import('@/view/pages/bills/BillInfo.vue'),
              meta: {
                title: 'Cập nhật hóa đơn bán lẻ - DDV',
              },
            },
            {
              path: 'print-bill',
              name: 'print-bill',
              component: () => import('@/view/pages/bills/PrintBill.vue'),
              meta: {
                title: 'In hóa đơn - DDV',
              },
            },
            {
              path: 'print-bill-mul',
              name: 'print-bill-mul',
              component: () =>
                import('@/view/pages/bills/PrintBillMultiple.vue'),
              meta: {
                title: 'In hóa đơn - DDV',
              },
            },
            {
              path: 'add-bill-from-order',
              name: 'add-bill-from-order',
              component: () => import('@/view/pages/orders/AddBill.vue'),
              meta: {
                title: 'Thêm mới hóa đơn từ đơn hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/note-books',
          component: () => import('@/view/pages/note-books/NoteBook.vue'),
          children: [
            {
              path: '/',
              name: 'list-note-book',
              component: () =>
                import('@/view/pages/note-books/ListNoteBook.vue'),
              meta: {
                title: 'Danh sách hóa đơn bán lẻ - DDV',
              },
            },
            {
              path: 'add-note-book',
              name: 'add-note-book',
              component: () =>
                import('@/view/pages/note-books/UpsertNoteBook.vue'),
              meta: {
                title: 'Thêm sổ tay - DDV',
              },
            },
            {
              path: 'update-note-book',
              name: 'update-note-book',
              component: () =>
                import('@/view/pages/note-books/UpsertNoteBook.vue'),
              meta: {
                title: 'Cập nhật sổ tay - DDV',
              },
            },
          ],
        },
        {
          path: '/bill-history',
          component: () => import('@/view/pages/bills/history/BillHistory.vue'),
          children: [
            {
              path: '/',
              name: 'list-bill-history',
              component: () =>
                import('@/view/pages/bills/history/ListBillHistory.vue'),
              meta: {
                title: 'Lịch sử hóa đơn - DDV',
              },
            },
            {
              path: 'detail-bill-history',
              name: 'detail-bill-history',
              component: () =>
                import('@/view/pages/bills/history/DetailBillHistory.vue'),
              meta: {
                title: 'Chi tiết lịch sử hóa đơn - DDV',
              },
            },
          ],
        },
        {
          path: '/order-history',
          component: () =>
            import('@/view/pages/orders/history/OrderHistory.vue'),
          children: [
            {
              path: '/',
              name: 'list-order-history',
              component: () =>
                import('@/view/pages/orders/history/ListOrderHistory.vue'),
              meta: {
                title: 'Lịch sử đơn hàng - DDV',
              },
            },
            {
              path: 'detail-order-history',
              name: 'detail-order-history',
              component: () =>
                import('@/view/pages/orders/history/DetailOrderHistory.vue'),
              meta: {
                title: 'Chi tiết lịch sử đơn hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/accessories',
          component: () => import('@/view/pages/accessories/Accessory.vue'),
          children: [
            {
              path: '/',
              name: 'list-accessory',
              component: () =>
                import('@/view/pages/accessories/ListAccessory.vue'),
              meta: {
                title: 'Danh sách bộ sản phẩm tặng kèm - DDV',
              },
            },
            {
              path: 'add-accessory',
              name: 'add-accessory',
              component: () =>
                import('@/view/pages/accessories/AddAccessory.vue'),
              meta: {
                title: 'Thêm mới bộ sản phẩm tặng kèm - DDV',
              },
            },
            {
              path: 'update-accessory',
              name: 'update-accessory',
              component: () =>
                import('@/view/pages/accessories/UpdateAccessory.vue'),
              meta: {
                title: 'Cập nhật bộ sản phẩm tặng kèm - DDV',
              },
            },
          ],
        },
        {
          path: '/promotion-accessories',
          component: () =>
            import('@/view/pages/promotion-accessories/PromotionAcc.vue'),
          children: [
            {
              path: '/',
              name: 'list-promotion-accessories',
              component: () =>
                import(
                  '@/view/pages/promotion-accessories/ListPromotionAcc.vue'
                ),
              meta: {
                title: 'Danh sách bộ sản phẩm khuyến mãi - DDV',
              },
            },
            {
              path: 'upsert-promotion-accessories',
              name: 'upsert-promotion-accessories',
              component: () =>
                import(
                  '@/view/pages/promotion-accessories/UpsertPromotion.vue'
                ),
              meta: {
                title: 'Thông tin bộ sản phẩm khuyến mãi - DDV',
              },
            },
          ],
        },
        {
          path: '/warranty-packages',
          component: () =>
            import('@/view/pages/warranty-package/WarrantyPackage.vue'),
          children: [
            {
              path: '/',
              name: 'list-warranty-packages',
              component: () => import('@/view/pages/warranty-package/List.vue'),
              meta: {
                title: 'Danh sách gói bảo hành - DDV',
              },
            },
            {
              path: 'add-warranty-package',
              name: 'add-warranty-package',
              component: () =>
                import('@/view/pages/warranty-package/WarrantyPackageInfo.vue'),
              meta: {
                title: 'Thêm mói gói bảo hành - DDV',
              },
            },
            {
              path: 'update-warranty-package',
              name: 'update-warranty-package',
              component: () =>
                import('@/view/pages/warranty-package/WarrantyPackageInfo.vue'),
              meta: {
                title: 'Cập nhật gói bảo hành - DDV',
              },
            },
          ],
        },
        {
          path: '/wholesaleInvoices',
          component: () => import('@/view/pages/bills/WholesaleInvoice.vue'),
          children: [
            {
              path: '/',
              name: 'list-wholesale-invoice',
              component: () => import('@/view/pages/bills/ListBill.vue'),
              meta: {
                title: 'Danh sách hóa đơn bán B2B - DDV',
              },
              props: { billType: 2 },
            },
            {
              path: 'add-wholesale-invoice',
              name: 'add-wholesale-invoice',
              component: () =>
                import('@/view/pages/bills/WholesaleBillInfo.vue'),
              meta: {
                title: 'Thêm mới hóa đơn bán B2B - DDV',
              },
            },
            {
              path: 'update-wholesale-invoice',
              name: 'update-wholesale-invoice',
              component: () =>
                import('@/view/pages/bills/WholesaleBillInfo.vue'),
              meta: {
                title: 'Cập nhật hóa đơn bán B2B - DDV',
              },
            },
          ],
        },
        {
          path: '/returnBills',
          component: () => import('@/view/pages/bills/ReturnBill.vue'),
          children: [
            {
              path: '/',
              name: 'list-return-bill',
              component: () => import('@/view/pages/bills/ListReturnBill.vue'),
              meta: {
                title: 'Danh sách hóa đơn trả hàng - DDV',
              },
            },
            {
              path: 'add-return-bill',
              name: 'add-return-bill',
              component: () => import('@/view/pages/bills/AddReturnBill.vue'),
              meta: {
                title: 'Thêm mới hóa đơn trả hàng - DDV',
              },
            },
            {
              path: 'update-return-bill',
              name: 'update-return-bill',
              component: () =>
                import('@/view/pages/bills/UpdateReturnBill.vue'),
              meta: {
                title: 'Cập nhật hóa đơn trả hàng - DDV',
              },
            },
            {
              path: 'add-return-bill-with-no-bill',
              name: 'add-return-bill-with-no-bill',
              component: () =>
                import('@/view/pages/bills/AddReturnBillWithNoBill.vue'),
            },
          ],
        },
        {
          path: '/settings',
          component: () => import('@/view/pages/settings/Setting.vue'),
          children: [
            {
              path: '/',
              name: 'list-setting',
              component: () => import('@/view/pages/settings/ListSetting.vue'),
              meta: {
                title: 'Cài đặt - DDV',
              },
            },
          ],
        },
        {
          path: '/imei-histories',
          component: () => import('@/view/pages/imeis/ImeiHistory.vue'),
          children: [
            {
              path: '/',
              name: 'list-imei-history',
              component: () => import('@/view/pages/imeis/ListImeiHistory.vue'),
              meta: {
                title: 'Lịch sử IMEI - DDV',
              },
            },
          ],
        },
        {
          path: '/external-categories',
          component: () =>
            import('@/view/pages/categories/ExternalCategories.vue'),
          children: [
            {
              path: '/',
              name: 'list-external-category',
              component: () =>
                import('@/view/pages/categories/ListExternalCate.vue'),
              meta: {
                title: 'Danh mục nội bộ - DDV',
              },
            },
          ],
        },
        {
          path: '/profile',
          component: () => import('@/view/pages/profile/Profile.vue'),
          children: [
            {
              path: 'change-password',
              name: 'change-password',
              component: () =>
                import('@/view/pages/profile/ChangePassword.vue'),
            },
            {
              path: 'change-store',
              name: 'change-store',
              component: () => import('@/view/pages/profile/ChangeStore.vue'),
            },
            {
              path: 'builder',
              name: 'builder',
              component: () => import('@/view/pages/builder/Builder.vue'),
            },
          ],
        },
        {
          path: '/productStock',
          component: () => import('@/view/pages/stocks/ProductStock.vue'),
          children: [
            {
              path: '',
              name: 'list-product-stock',
              component: () =>
                import('@/view/pages/stocks/ListProductStock.vue'),
              meta: {
                title: 'Danh sách tồn kho - DDV',
              },
            },
          ],
        },
        {
          path: '/reports',
          component: () => import('@/view/pages/reports/Reports.vue'),
          children: [
            {
              path: 'accounting/cash',
              name: 'report-accounting-cash',
              component: () =>
                import('@/view/pages/reports/accounting/ListByStore.vue'),
              meta: {
                title: 'Báo cáo thu chi theo cửa hàng - DDV',
              },
            },
            {
              path: 'accounting/money',
              name: 'report-accounting-money',
              component: () =>
                import(
                  '@/view/pages/reports/accounting/SaleSummariesByStore.vue'
                ),
              meta: {
                title: 'Báo cáo tổng tiền theo cửa hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/order-cant-serve',
          component: () =>
            import('@/view/pages/order-cant-serve/OrderCantServe.vue'),
          children: [
            {
              path: '',
              name: 'order-cant-serve',
              component: () =>
                import('@/view/pages/order-cant-serve/ListOrderCantServe.vue'),
              meta: {
                title: 'Danh sách phiếu không phục vụ được',
              },
            },
          ],
        },
        {
          path: '/customer-serve',
          component: () =>
            import('@/view/pages/customer-serve/CustomerServe.vue'),
          children: [
            {
              path: '',
              name: 'customer-serve',
              component: () =>
                import('@/view/pages/customer-serve/ListCustomerServe.vue'),
              meta: {
                title: 'Thống kê lượt phục vụ',
              },
            },
          ],
        },
        {
          path: '/posm',
          component: () => import('@/view/pages/posm/POSM.vue'),
          children: [
            {
              path: 'campaigns',
              name: 'posm-form-campaigns',
              component: () =>
                import('@/view/pages/posm/campaign/CampaignList.vue'),
              meta: {
                title: 'Danh sách các Campaign POSM - DDV',
              },
            },
            {
              path: 'campaigns/create',
              name: 'posm-form-campaigns-add',
              component: () => import('@/view/pages/posm/campaign/Form.vue'),
              meta: {
                title: 'Tạo Campaign mới cho form POSM -DDV',
              },
            },
            {
              path: 'campaigns/edit/:id',
              name: 'posm-form-campaigns-edit',
              component: () => import('@/view/pages/posm/campaign/Form.vue'),
              meta: {
                title: 'Sửa đổi Campaign cho form POSM -DDV',
              },
            },
            {
              path: 'overview',
              name: 'posm-form-overview',
              component: () => import('@/view/pages/posm/List.vue'),
              meta: {
                title: 'Danh sách các Form POSM - DDV',
              },
            },
            {
              path: 'create',
              name: 'posm-form-create',
              component: () => import('@/view/pages/posm/Form.vue'),
              meta: {
                title: 'Tạo đơn POSM mới - DDV',
              },
            },
            {
              path: 'edit/:uuid',
              name: 'posm-form-edit',
              component: () => import('@/view/pages/posm/Form.vue'),
              meta: {
                title: 'Cập nhật đơn POSM - DDV',
              },
            },
            {
              path: 'fill-in',
              name: 'posm-form-fill-in',
              component: () => import('@/view/pages/posm/FillInForm.vue'),
              meta: {
                title: 'Điền đơn POSM - DDV',
              },
            },
            {
              path: 'import-excel',
              name: 'posm-form-import-excel',
              component: () => import('@/view/pages/posm/ImportExcel.vue'),
              meta: {
                title: 'Import Excel tạo Form Posm - DDV',
              },
            },
            {
              path: 'submissions',
              name: 'posm-form-submissions',
              component: () => import('@/view/pages/posm/submission/List.vue'),
              meta: {
                title: 'Danh sách đơn POSM đã gửi - DDV',
              },
            },
            {
              path: 'submissions/view/:uuid',
              name: 'posm-form-view-submission',
              component: () => import('@/view/pages/posm/submission/Form.vue'),
              meta: {
                title: 'Xem đơn POSM đã điền - DDV',
              },
            },
          ],
        },
        {
          path: '/points',
          component: () => import('@/view/pages/promotions-point/Point.vue'),
          children: [
            {
              path: '/',
              name: 'point-setting',
              component: () =>
                import('@/view/pages/promotions-point/PointSetting.vue'),
              meta: {
                title: 'Điểm tích lũy - DDV',
              },
            },
            {
              path: 'add-point-program',
              name: 'add-point-program',
              component: () =>
                import('@/view/pages/promotions-point/AddPointProgram.vue'),
              meta: {
                title: 'Chương trình điểm tích lũy - DDV',
              },
            },
            {
              path: 'update-point-program',
              name: 'update-point-program',
              component: () =>
                import('@/view/pages/promotions-point/UpdatePointProgram.vue'),
              meta: {
                title: 'Cập nhật chương trình điểm tích lũy - DDV',
              },
            },
          ],
        },
        {
          path: '/customer-care-form',
          component: () =>
            import('@/view/pages/customer-care/CustomerCareForm.vue'),
          children: [
            {
              path: '/',
              name: 'list-customer-care-form',
              component: () =>
                import('@/view/pages/customer-care/ListCustomerCareForm.vue'),
              meta: {
                title: 'Hình thức chăm sóc khách hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/customer-transfer',
          component: () =>
            import('@/view/pages/customer-transfer-point/PoinTransfer.vue'),
          children: [
            {
              path: '/',
              name: 'customer-transfer',
              component: () =>
                import('@/view/pages/customer-transfer-point/List.vue'),
              meta: {
                title: 'Danh sách các phiếu chuyển điểm',
              },
            },
            {
              path: '/customer-transfer-statistical',
              name: 'customer-transfer-statistical',
              component: () =>
                import('@/view/pages/customer-transfer-point/Statistical.vue'),
              meta: {
                title: 'Thông kê chuyển điểm tiêu dùng',
              },
            },
            {
              path: 'add',
              name: 'customer-transfer-add',
              component: () =>
                import('@/view/pages/customer-transfer-point/Add.vue'),
              meta: {
                title: 'Tạo phiếu chuyển điểm',
              },
            },
            {
              path: 'update',
              name: 'customer-transfer-update',
              component: () =>
                import('@/view/pages/customer-transfer-point/Update.vue'),
              meta: {
                title: 'Cập nhật phiếu chuyển điểm',
              },
            },
          ],
        },
        {
          path: '/customer-care',
          component: () =>
            import('@/view/pages/customer-care/CustomerCare.vue'),
          children: [
            {
              path: '/',
              name: 'list-customer-care',
              component: () =>
                import('@/view/pages/customer-care/ListCustomerCare.vue'),
              meta: {
                title: 'Chăm sóc khách hàng - DDV',
              },
            },
            {
              path: 'add-customer-care',
              name: 'add-customer-care',
              component: () =>
                import('@/view/pages/customer-care/AddCustomerCare.vue'),
              meta: {
                title: 'Thêm mới phiếu chăm sóc khách hàng - DDV',
              },
            },
            {
              path: 'update-customer-care',
              name: 'update-customer-care',
              component: () =>
                import('@/view/pages/customer-care/UpdateCustomerCare.vue'),
              meta: {
                title: 'Cập nhật phiếu chăm sóc khách hàng - DDV',
              },
            },
          ],
        },
        {
          path: '/discount-programs',
          component: () =>
            import('@/view/pages/discount-program/DiscountProgram.vue'),
          children: [
            {
              path: '/',
              name: 'list-discount-program',
              component: () => import('@/view/pages/discount-program/List.vue'),
              meta: {
                title: 'Danh sách chương trình chiết khấu - DDV',
              },
            },
            {
              path: 'add',
              name: 'add-discount-program',
              component: () => import('@/view/pages/discount-program/Add.vue'),
              meta: {
                title: 'Thêm mới chương trình chiết khấu - DDV',
              },
            },
            {
              path: 'update/:discountProgramId',
              name: 'update-discount-program',
              component: () =>
                import('@/view/pages/discount-program/Update.vue'),
              meta: {
                title: 'Cập nhật chương trình chiết khấu - DDV',
              },
            },
            {
              path: 'upsert-discount-program',
              name: 'upsert-discount-program',
              component: () =>
                import('@/view/pages/discount-program/Upsert.vue'),
              meta: {
                title: 'Chương trình chiết khấu - DDV',
              },
            },
          ],
        },
        {
          path: '/tradein-programs',
          component: () =>
            import('@/view/pages/tradein-program/TradeInProgram.vue'),
          children: [
            {
              path: '/',
              name: 'list-tradein-program',
              component: () => import('@/view/pages/tradein-program/List.vue'),
              meta: {
                title: 'Danh sách chương trình thu cũ - DDV',
              },
            },
            {
              path: 'add',
              name: 'add-tradein-program',
              component: () => import('@/view/pages/tradein-program/Add.vue'),
              meta: {
                title: 'Thêm mới chương trình thu cũ - DDV',
              },
            },
            {
              path: 'update',
              name: 'update-tradein-program',
              component: () =>
                import('@/view/pages/tradein-program/Update.vue'),
              meta: {
                title: 'Cập nhật chương trình thu cũ - DDV',
              },
            },
          ],
        },
        {
          path: '/trade-in',
          component: () => import('@/view/pages/trade-in/TradeIn.vue'),
          children: [
            {
              path: '/',
              name: 'list-trade-in',
              component: () => import('@/view/pages/trade-in/List.vue'),
              meta: {
                title: 'Danh sách phiếu định giá - DDV',
              },
            },
            {
              path: 'update',
              name: 'update-trade-in',
              component: () => import('@/view/pages/trade-in/Update.vue'),
              meta: {
                title: 'Thêm mới phiếu định giá - DDV',
              },
            },
            {
              path: 'import-trade-in',
              name: 'import-trade-in',
              component: () =>
                import('@/view/pages/trade-in/ImportTradeIn.vue'),
              meta: {
                title: 'Tạo phiếu thu cũ - DDV',
              },
            },
          ],
        },
        {
          path: '/deleted-trade-in',
          component: () => import('@/view/pages/trade-in/DeletedTradeIn.vue'),
          children: [
            {
              path: '/',
              name: 'list-deleted-trade-in',
              component: () =>
                import('@/view/pages/trade-in/ListDeletedTradeIn.vue'),
              meta: {
                title: 'Danh sách phiếu định giá đã xoá - DDV',
              },
            },
          ],
        },
        {
          path: '/import-trade-in',
          component: () => import('@/view/pages/trade-in/ITradeIn.vue'),
          children: [
            {
              path: '/',
              name: 'list-import-trade-in',
              component: () => import('@/view/pages/trade-in/ListITradeIn.vue'),
              meta: {
                title: 'Danh sách phiếu thu cũ - DDV',
              },
            },
            {
              path: 'update-import-trade-in',
              name: 'update-import-trade-in',
              component: () =>
                import('@/view/pages/trade-in/UpdateImportTradeIn.vue'),
              meta: {
                title: 'Cập nhật phiếu thu cũ - DDV',
              },
            },
            {
              path: 'print-import-trade-in',
              name: 'print-import-trade-in',
              component: () =>
                import('@/view/pages/trade-in/PrintImportTradeIn.vue'),
              meta: {
                title: 'In phiếu thu cũ - DDV',
              },
            },
          ],
        },
        {
          path: '/bill-deleted',
          component: () => import('@/view/pages/bills/BillDeleted.vue'),
          children: [
            {
              path: '/',
              name: 'list-bill-deleted',
              component: () => import('@/view/pages/bills/ListBillDeleted.vue'),
              meta: {
                title: 'Danh sách hóa đơn đã xóa - DDV',
              },
            },
            {
              path: 'retail-bill-deleted',
              name: 'retail-bill-deleted',
              component: () =>
                import('@/view/pages/bills/RetailBillDeleted.vue'),
              meta: {
                title: 'Chi tiết hóa đơn đã xóa - DDV',
              },
            },
            {
              path: 'wholesale-bill-deleted',
              name: 'wholesale-bill-deleted',
              component: () =>
                import('@/view/pages/bills/WholesaleBillDeleted.vue'),
              meta: {
                title: 'Chi tiết hóa đơn đẵ xòa - DDV',
              },
            },
          ],
        },
        {
          path: '/companies',
          component: () => import('@/view/pages/companies/Company.vue'),
          children: [
            {
              path: '/',
              name: 'list-company',
              component: () => import('@/view/pages/companies/List.vue'),
              meta: {
                title: 'Danh sách doanh nghiệp - DDV',
              },
            },
          ],
        },
        {
          path: '/reward',
          component: () => import('@/view/pages/reward/Reward.vue'),
          children: [
            {
              path: 'reward-category',
              name: 'reward-category',
              component: () =>
                import('@/view/pages/reward/ListRewardCategory.vue'),
              meta: {
                title: 'Danh sách nhóm sản phẩm hoa hồng - DDV',
              },
            },
            {
              path: 'reward-program',
              name: 'reward-program',
              component: () =>
                import('@/view/pages/reward/ListRewardProgram.vue'),
              meta: {
                title:
                  'Danh sách chương trình thưởng thưởng hoa hồng NCH - DDV',
              },
            },
            {
              path: 'reward-accessory-program',
              name: 'reward-accessory-program',
              component: () =>
                import('@/view/pages/reward/ListRewardAccessoryProgram.vue'),
              meta: {
                title: 'Danh sách chương trình thưởng hoa hồng NHPK - DDV',
              },
            },
            {
              path: 'reward-month-phone-program',
              name: 'reward-month-phone-program',
              component: () =>
                import('@/view/pages/reward/ListRewardMonthPhoneProgram.vue'),
              meta: {
                title:
                  'Danh sách chương trình thưởng hoa hồng NĐT - DDV (Theo tháng)',
              },
            },
            {
              path: 'assessory-industry-expense',
              name: 'assessory-industry-expense',
              component: () =>
                import('@/view/pages/reward/ListAssessoryIndustryExpense.vue'),
              meta: {
                title: 'Danh sách nhóm chi phí - DDV',
              },
            },
            {
              path: 'add-reward-category',
              name: 'add-reward-category',
              component: () =>
                import('@/view/pages/reward/AddRewardCategory.vue'),
              meta: {
                title: 'Thêm mới nhóm sản phẩm hoa hồng - DDV',
              },
            },
            {
              path: 'update-reward-category',
              name: 'update-reward-category',
              component: () =>
                import('@/view/pages/reward/UpdateRewardCategory.vue'),
              meta: {
                title: 'Cập nhật nhóm sản phẩm hoa hồng - DDV',
              },
            },
            {
              path: 'add-reward-program',
              name: 'add-reward-program',
              component: () =>
                import('@/view/pages/reward/AddRewardProgram.vue'),
              meta: {
                title: 'Thêm mới chương trình thưởng hoa hồng - DDV',
              },
            },
            {
              path: 'update-reward-program',
              name: 'update-reward-program',
              component: () =>
                import('@/view/pages/reward/UpdateRewardProgram.vue'),
              meta: {
                title: 'Cập nhật chương trình thưởng hoa hồng - DDV',
              },
            },
            {
              path: 'add-reward-accessory-program',
              name: 'add-reward-accessory-program',
              component: () =>
                import('@/view/pages/reward/AddRewardAccessoryProgram.vue'),
              meta: {
                title: 'Thêm mới chương trình thưởng hoa hồng - DDV',
              },
            },
            {
              path: 'update-reward-accessory-program',
              name: 'update-reward-accessory-program',
              component: () =>
                import('@/view/pages/reward/UpdateRewardAccessoryProgram.vue'),
              meta: {
                title: 'Cập nhật chương trình thưởng hoa hồng - DDV',
              },
            },
            {
              path: 'add-reward-month-phone-program',
              name: 'add-reward-month-phone-program',
              component: () =>
                import('@/view/pages/reward/AddRewardMonthPhoneProgram.vue'),
              meta: {
                title: 'Thêm mới chương trình thưởng hoa hồng - DDV',
              },
            },
            {
              path: 'update-reward-month-phone-program',
              name: 'update-reward-month-phone-program',
              component: () =>
                import('@/view/pages/reward/UpdateRewardMonthPhoneProgram.vue'),
              meta: {
                title: 'Cập nhật chương trình thưởng hoa hồng - DDV',
              },
            },
            {
              path: 'add-assessory-industry-expense',
              name: 'add-assessory-industry-expense',
              component: () =>
                import('@/view/pages/reward/AddAssessoryIndustryExpense.vue'),
              meta: {
                title: 'Thêm mới chương trình thưởng hoa hồng NHPK - DDV',
              },
            },
            {
              path: 'update-assessory-industry-expense',
              name: 'update-assessory-industry-expense',
              component: () =>
                import(
                  '@/view/pages/reward/UpdateAssessoryIndustryExpense.vue'
                ),
              meta: {
                title: 'Cập nhật chương trình thưởng hoa hồng NHPK - DDV',
              },
            },
            {
              path: 'reward-detail',
              name: 'reward-detail',
              component: () =>
                import('@/view/pages/reward/ListRewardDetail.vue'),
              meta: {
                title: 'Cộng trừ khác hoa hồng cửa hàng - DDV',
              },
            },
            {
              path: 'reward-accessory-detail',
              name: 'reward-accessory-detail',
              component: () =>
                import('@/view/pages/reward/ListRewardAccessoryDetail.vue'),
              meta: {
                title: 'Cộng trừ khác hoa hồng Ngành hàng phụ kiện - DDV',
              },
            },
          ],
        },
        {
          path: '/area',
          component: () => import('@/view/pages/area/Area.vue'),
          children: [
            {
              path: '/',
              name: 'list-area',
              component: () => import('@/view/pages/area/List.vue'),
              meta: {
                title: 'Danh sách khu vực - DDV',
              },
            },
          ],
        },
        {
          path: '/region',
          component: () => import('@/view/pages/region/Region.vue'),
          children: [
            {
              path: '/',
              name: 'list-region',
              component: () => import('@/view/pages/region/List.vue'),
              meta: {
                title: 'Danh sách vùng - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-reports',
          component: () =>
            import('@/view/pages/reward-report/RewardReport.vue'),
          children: [
            {
              path: 'overview',
              name: 'overview',
              component: () =>
                import('@/view/pages/reward-report/Overview.vue'),
              meta: {
                title: 'Báo cáo hoa hồng tổng hợp theo cửa hàng - DDV',
              },
            },
            {
              path: 'detail',
              name: 'detail',
              component: () => import('@/view/pages/reward-report/Detail.vue'),
              meta: {
                title: 'Báo cáo hoa hồng chi tiết theo cửa hàng - DDV',
              },
            },
            {
              path: 'list-bill-by-store',
              name: 'list-bill-by-store',
              component: () =>
                import('@/view/pages/reward-report/ListBillByStore.vue'),
              meta: {
                title: 'Danh sách hóa đơn theo cửa hàng - DDV',
              },
            },
            {
              path: 'detail-by-employee',
              name: 'detail-by-employee',
              component: () =>
                import('@/view/pages/reward-report/DetailByEmployee.vue'),
              meta: {
                title: 'Báo cáo hoa hồng chi tiết theo nhân viên - DDV',
              },
            },
            {
              path: 'list-bill-by-employee',
              name: 'list-bill-by-employee',
              component: () =>
                import('@/view/pages/reward-report/ListBillByEmployee.vue'),
              meta: {
                title: 'Danh sách hóa đơn theo nhân viên - DDV',
              },
            },
          ],
        },
        {
          path: '/warranty-info',
          component: () =>
            import('@/view/pages/warranty-info/WarrantyInfo.vue'),
          children: [
            {
              path: '/',
              name: 'list-warranty-info',
              component: () => import('@/view/pages/warranty-info/List.vue'),
              meta: {
                title: 'Danh sách thông tin bảo hành - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-accessory-reports',
          component: () =>
            import('@/view/pages/reward-report/RewardAccessoryReport.vue'),
          children: [
            {
              path: '/',
              name: 'reward-accessory-overview',
              component: () =>
                import(
                  '@/view/pages/reward-report/OverviewAccessoryReport.vue'
                ),
              meta: {
                title: 'Báo cáo hoa hồng NHPK - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-phone-reports',
          component: () =>
            import('@/view/pages/reward-phone-report/RewardPhoneReport.vue'),
          children: [
            {
              path: 'month-reports',
              name: 'month-reports',
              component: () =>
                import(
                  '@/view/pages/reward-phone-report/OverviewMonthReport.vue'
                ),
              meta: {
                title: 'Báo cáo hoa hồng tổng hợp - DDV',
              },
            },
          ],
        },
        {
          path: '/product-component-categories',
          component: () =>
            import(
              '@/view/pages/product-component-categories/ProductComponentCategory.vue'
            ),
          children: [
            {
              path: '/',
              name: 'list-product-component-category',
              component: () =>
                import('@/view/pages/product-component-categories/List.vue'),
              meta: {
                title: 'Danh mục linh kiện - DDV',
              },
            },
          ],
        },
        {
          path: '/product-component',
          component: () =>
            import('@/view/pages/product-component/ProductComponent.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-component',
              component: () =>
                import('@/view/pages/product-component/List.vue'),
              meta: {
                title: 'Danh sách linh kiện - DDV',
              },
            },
            {
              path: 'import-product-component',
              name: 'import-product-component',
              component: () =>
                import('@/view/pages/product-component/Import.vue'),
              meta: {
                title: 'Nhập sản phẩm linh kiện - DDV',
              },
            },
          ],
        },
        {
          path: '/product-component-group',
          component: () =>
            import(
              '@/view/pages/product-component-group/ProductComponentGroup.vue'
            ),
          children: [
            {
              path: '/',
              name: 'list-product-component-group',
              component: () =>
                import('@/view/pages/product-component-group/List.vue'),
              meta: {
                title: 'Danh sách nhóm sản phẩm linh kiện - DDV',
              },
            },
            {
              path: 'add-product-component-group',
              name: 'add-product-component-group',
              component: () =>
                import('@/view/pages/product-component-group/Add.vue'),
              meta: {
                title: 'Thêm mới nhóm sản phẩm linh kiện - DDV',
              },
            },
            {
              path: 'update-product-component-group',
              name: 'update-product-component-group',
              component: () =>
                import('@/view/pages/product-component-group/Update.vue'),
              meta: {
                title: 'Cập nhật nhóm sản phẩm linh kiện - DDV',
              },
            },
          ],
        },
        {
          path: '/transfer-stock-to-nhanh',
          component: () => import('@/view/pages/stocks/TransferToNhanh.vue'),
          children: [
            {
              path: 'update-transfer-stock-to-nhanh',
              name: 'update-transfer-stock-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/UpdateTStockToNhanh.vue'),
              meta: {
                title: 'Cập nhật phiếu chuyển kho sang hệ thống cũ - DDV',
              },
            },
          ],
        },
        {
          path: '/require-stock-to-nhanh',
          component: () => import('@/view/pages/stocks/RStockToNhanh.vue'),
          children: [
            {
              path: '/',
              name: 'list-require-stock-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/ListRStockToNhanh.vue'),
              meta: {
                title:
                  'Danh sách phiếu yêu cầu chuyển kho sang hệ thống cũ - DDV',
              },
            },
            {
              path: 'approve-transfer-stock-to-nhanh',
              name: 'approve-transfer-stock-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/ApproveTransferToNhanh.vue'),
              meta: {
                title: 'Duyệt phiếu yêu cầu chuyển kho sang hệ thống cũ - DDV',
              },
            },
          ],
        },
        {
          path: '/coupon',
          component: () => import('@/view/pages/coupon/Coupon.vue'),
          children: [
            {
              path: '/',
              name: 'list-coupon',
              component: () => import('@/view/pages/coupon/List.vue'),
              meta: {
                title: 'Danh sách chương trình coupon - DDV',
              },
            },
            {
              path: 'add-coupon',
              name: 'add-coupon',
              component: () => import('@/view/pages/coupon/Add.vue'),
              meta: {
                title: 'Thêm mới chương trình coupon - DDV',
              },
            },
            {
              path: 'update-coupon',
              name: 'update-coupon',
              component: () => import('@/view/pages/coupon/Update.vue'),
              meta: {
                title: 'Cập nhật chương trình coupon - DDV',
              },
            },
          ],
        },
        {
          path: '/revenue-report',
          component: () =>
            import('@/view/pages/revenue-report/RevenueReport.vue'),
          children: [
            {
              path: 'by-product',
              name: 'by-product',
              component: () =>
                import('@/view/pages/revenue-report/ByProduct.vue'),
              meta: {
                title: 'Báo cáo doanh thu theo sản phẩm - DDV',
              },
            },
          ],
        },
        {
          path: '/transaction-categories',
          component: () =>
            import('@/view/pages/transaction-category/TransactionCategory.vue'),
          children: [
            {
              path: '/',
              name: 'list-transaction-categories',
              component: () =>
                import('@/view/pages/transaction-category/List.vue'),
              meta: {
                title: 'Danh sách danh mục thu chi - DDV',
              },
            },
          ],
        },
        {
          path: 'qr-code-history',
          component: () => import('@/view/pages/qr-code-history/History.vue'),
          children: [
            {
              path: '/',
              name: 'qr-code-history',
              component: () => import('@/view/pages/qr-code-history/List.vue'),
              meta: {
                title: 'Lịch sử thanh toán - DDV',
              },
            },
          ],
        },
        {
          path: '/product-stockslip',
          component: () => import('@/view/pages/stocks/ProductStockSlip.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-stockslip',
              component: () =>
                import('@/view/pages/stocks/ListProductStockSlip.vue'),
              meta: {
                title: 'Danh sách sản phẩm XNK - DDV',
              },
            },
          ],
        },
        {
          path: '/product-stockslip-reports',
          component: () =>
            import('@/view/pages/product-stockslip/ProductStockSlip.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-stockslip-reports',
              component: () =>
                import('@/view/pages/product-stockslip/List.vue'),
              meta: {
                title: 'Báo cáo sản phẩm XNK - DDV',
              },
            },
          ],
        },
        {
          path: '/product-sale-reports',
          component: () =>
            import('@/view/pages/product-stockslip/ProductSale.vue'),
          children: [
            {
              path: '',
              name: 'list-product-sale-reports',
              component: () =>
                import('@/view/pages/product-stockslip/ListProductSale.vue'),
              meta: {
                title: 'Báo cáo sản phẩm bán chạy - DDV',
              },
            },
          ],
        },
        {
          path: '/product-imei-sale-reports',
          component: () =>
            import('@/view/pages/product-imei-sale/ProductIMEISale.vue'),
          children: [
            {
              path: '',
              name: 'list-product-imei-sale-reports',
              component: () =>
                import('@/view/pages/product-imei-sale/List.vue'),
              meta: {
                title: 'Tổng hợp SP IMEI đã bán - DDV',
              },
            },
          ],
        },
        {
          path: '/inventory-balance',
          component: () =>
            import('@/view/pages/inventory-balance/InventoryBalance.vue'),
          children: [
            {
              path: '/',
              name: 'list-inventory-balance',
              component: () =>
                import('@/view/pages/inventory-balance/List.vue'),
              meta: {
                title: 'Danh sách phiếu cân kho - DDV',
              },
            },
            {
              path: 'add-inventory-balance',
              name: 'add-inventory-balance',
              component: () => import('@/view/pages/inventory-balance/Add.vue'),
              meta: {
                title: 'Thêm mới phiếu cân kho - DDV',
              },
            },
          ],
        },
        {
          path: '/imei-balance',
          component: () =>
            import('@/view/pages/inventory-balance/IMEIBalance.vue'),
          children: [
            {
              path: '/',
              name: 'imei-balance',
              component: () =>
                import('@/view/pages/inventory-balance/ImportIMEIBalance.vue'),
              meta: {
                title: 'Nhập file cân kho IMEI - DDV',
              },
            },
          ],
        },
        {
          path: '/tools',
          component: () => import('@/view/pages/tools/Tool.vue'),
          children: [
            {
              path: 'list',
              name: 'list-cron-job',
              component: () => import('@/view/pages/tools/List.vue'),
              meta: {
                title: 'Cron job - DDV',
              },
            },
            {
              path: 'sync-product',
              name: 'sync-product',
              component: () => import('@/view/pages/tools/ProductSync.vue'),
              meta: {
                title: 'Đồng bộ sản phẩm - DDV',
              },
            },
            {
              path: 'sync-imei',
              name: 'sync-imei',
              component: () => import('@/view/pages/tools/Imei.vue'),
            },
            {
              path: 'sync-bill',
              name: 'sync-bill',
              component: () => import('@/view/pages/tools/BillSync.vue'),
              meta: {
                title: 'Đồng bộ hóa đơn - DDV',
              },
            },
            {
              path: 'detail-sync/:code',
              name: 'detail-sync-edit',
              props: true,
              component: () => import('@/view/pages/tools/DetailSync.vue'),
            },
            {
              path: 'detail-sync/',
              name: 'detail-sync-create',
              component: () => import('@/view/pages/tools/DetailSync.vue'),
            },
          ],
        },
        {
          path: '/import-warranty-stocks',
          component: () =>
            import('@/view/pages/warranty-stock/ImportWarranty.vue'),
          children: [
            {
              path: '/',
              name: 'list-import-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/ListImport.vue'),
              meta: {
                title: 'Danh sách phiếu nhập kho bảo hành - DDV',
              },
            },
            {
              path: 'add-import-warranty',
              name: 'add-import-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/AddImport.vue'),
              meta: {
                title: 'Thêm mới phiếu nhập kho bảo hành - DDV',
              },
            },
            {
              path: 'update-import-warranty',
              name: 'update-import-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/UpdateImport.vue'),
              meta: {
                title: 'Cập nhật phiếu nhập kho bảo hành - DDV',
              },
            },
          ],
        },
        {
          path: '/export-warranty-stocks',
          component: () =>
            import('@/view/pages/warranty-stock/ExportWarranty.vue'),
          children: [
            {
              path: '/',
              name: 'list-export-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/ListExport.vue'),
              meta: {
                title: 'Danh sách phiếu xuất kho bảo hành - DDV',
              },
            },
            {
              path: 'add-export-warranty',
              name: 'add-export-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/AddExport.vue'),
              meta: {
                title: 'Thêm mới phiếu xuất kho bảo hành - DDV',
              },
            },
            {
              path: 'update-export-warranty',
              name: 'update-export-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/UpdateExport.vue'),
              meta: {
                title: 'Cập nhật phiếu xuất kho bảo hành - DDV',
              },
            },
          ],
        },
        {
          path: '/inventory-check',
          component: () =>
            import('@/view/pages/inventoryCheck/InventoryCheck.vue'),
          children: [
            {
              path: '/',
              name: 'list-inventory-check',
              component: () =>
                import('@/view/pages/inventoryCheck/ListInventoryCheck.vue'),
              meta: {
                title: 'Danh sách phiếu kiểm tra tồn kho - DDV',
              },
            },
            {
              path: 'upsert',
              name: 'upsert-inventory-check',
              component: () =>
                import('@/view/pages/inventoryCheck/UpsertInventoryCheck.vue'),
              // props: true,
              meta: {
                title: 'Phiếu kiểm tra tồn kho - DDV',
              },
            },
            {
              path: '/print-inventory-check',
              name: 'print-inventory-check',
              component: () =>
                import('@/view/pages/inventoryCheck/PrintInventoryCheck.vue'),
              meta: {
                title: 'In phiếu truy thu tồn kho - DDV',
              },
            },
          ],
        },
        {
          path: '/import-check-sheet-stocks',
          name: 'import-check-sheet-stocks',
          component: () =>
            import('@/view/pages/inventoryCheck/ImportStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-import-check-sheet-stock',
              component: () =>
                import('@/view/pages/inventoryCheck/ListImportStock.vue'),
              meta: {
                title: 'Danh sách phiếu nhập kho từ phiếu kiểm tồn - DDV',
              },
            },
            {
              path: '/update',
              name: 'update-import-check-sheet-stock',
              component: () =>
                import('@/view/pages/inventoryCheck/UpdateImportStock.vue'),
              meta: {
                title: 'Chi tiết phiếu xuất nhập kho bảo hành - DDV',
              },
            },
          ],
        },
        {
          path: '/export-check-sheet-stocks',
          name: 'export-check-sheet-stocks',
          component: () =>
            import('@/view/pages/inventoryCheck/ExportStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-export-check-sheet-stock',
              component: () =>
                import('@/view/pages/inventoryCheck/ListExportStock.vue'),
              meta: {
                title: 'Danh sách phiếu xuất kho từ phiếu kiểm tồn - DDV',
              },
            },
            {
              path: '/update',
              name: 'update-export-check-sheet-stock',
              component: () =>
                import('@/view/pages/inventoryCheck/UpdateExportStock.vue'),
              meta: {
                title: 'Cập nhập phiếu xuất kho từ phiếu kiểm tồn - DDV',
              },
            },
          ],
        },
        {
          path: '/stock-debts',
          name: 'stock-debts',
          component: () => import('@/view/pages/inventoryCheck/StockDebt.vue'),
          children: [
            {
              path: '/',
              name: 'list-stock-debt',
              component: () =>
                import('@/view/pages/inventoryCheck/ListStockDebt.vue'),
              meta: {
                title: 'Danh sách phiếu truy thu tồn kho',
              },
            },
          ],
        },
        {
          path: '/storage-time',
          component: () => import('@/view/pages/storage-times/StorageTime.vue'),
          children: [
            {
              path: '/',
              name: 'list-storage-time',
              component: () => import('@/view/pages/storage-times/List.vue'),
              meta: {
                title: 'Danh sách thời gian lưu kho - DDV',
              },
            },
          ],
        },
        {
          path: '/virtual-stock',
          component: () =>
            import('@/view/pages/virtual-stock/VirtualStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-virtual-stock',
              component: () => import('@/view/pages/virtual-stock/List.vue'),
              meta: {
                title: 'Cấu hình cho phép tồn ảo sản phẩm- DDV',
              },
            },
            {
              path: 'info-virtual-stock',
              name: 'info-virtual-stock',
              component: () => import('@/view/pages/virtual-stock/Info.vue'),
              meta: {
                title: 'Cấu hình nhóm tồn ảo sản phẩm- DDV',
              },
            },
          ],
        },
        {
          path: '/product-price-config',
          component: () =>
            import('@/view/pages/product-price/ProductPriceIndex.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-price',
              component: () =>
                import('@/view/pages/product-price/ProductPrice.vue'),
              meta: {
                title: 'Danh sách cấu hình sản phẩm theo chi nhánh',
              },
            },
            {
              path: 'update/:id',
              name: 'update-product-price',
              component: () =>
                import('@/view/pages/product-price/UpdateProductPrice.vue'),
              meta: {
                title: 'Cập nhật cấu hình sản phẩm theo chi nhánh',
              },
            },
          ],
        },
        {
          path: '/reward-cr-reports',
          component: () =>
            import('@/view/pages/reward-cr-report/RewardCRReport.vue'),
          children: [
            {
              path: 'overview',
              name: 'overview',
              component: () =>
                import('@/view/pages/reward-cr-report/Overview.vue'),
              meta: {
                title: 'Báo cáo hoa hồng CSKH- DDV',
              },
            },
          ],
        },
        {
          path: '/purchase-orders',
          component: () =>
            import('@/view/pages/purchase-orders/PurchaseOrder.vue'),
          children: [
            {
              path: '/',
              name: 'list-purchase-orders',
              component: () => import('@/view/pages/purchase-orders/List.vue'),
              meta: {
                title: 'Phiếu yêu cầu nhập hàng- DDV',
              },
            },
            {
              path: 'info-purchase-orders',
              name: 'info-purchase-orders',
              component: () => import('@/view/pages/purchase-orders/Info.vue'),
              meta: {
                title: 'Thông tin phiếu yêu cầu nhập hàng- DDV',
              },
            },
            {
              path: 'share-purchase-orders',
              name: 'share-purchase-orders',
              component: () =>
                import('@/view/pages/purchase-orders/POShare.vue'),
              meta: {
                title: 'Thông tin phiếu yêu cầu nhập hàng- DDV',
              },
            },
            {
              path: 'update-purchase-orders',
              name: 'update-purchase-orders',
              component: () => import('@/view/pages/purchase-orders/Info.vue'),
              meta: {
                title: 'Thông tin phiếu yêu cầu nhập hàng- DDV',
              },
            },
            {
              path: 'approve-purchase-orders',
              name: 'approve-purchase-orders',
              component: () =>
                import('@/view/pages/purchase-orders/Approve.vue'),
              meta: {
                title: 'Duyệt phiếu yêu cầu nhập hàng- DDV',
              },
            },
            {
              path: 'print-purchase-orders',
              name: 'print-purchase-orders',
              component: () => import('@/view/pages/purchase-orders/Print.vue'),
              meta: {
                title: 'In phiếu yêu cầu nhập hàng- DDV',
              },
            },
            {
              path: 'approve-po-share',
              name: 'approve-po-share',
              component: () =>
                import('@/view/pages/purchase-orders/ApprovePOShare.vue'),
              meta: {
                title: 'Duyệt phiếu PO chia hàng- DDV',
              },
            },
          ],
        },
        {
          path: '/import-purchase-orders',
          component: () =>
            import('@/view/pages/purchase-orders/PurchaseOrder.vue'),
          children: [
            {
              path: '/',
              name: 'import-purchase-orders',
              component: () =>
                import('@/view/pages/purchase-orders/ListApproved.vue'),
              meta: {
                title: 'Phiếu nhập hàng- DDV',
              },
            },
          ],
        },
        {
          path: '/vat-invoice-v2',
          component: () => import('@/view/pages/vat-invoice/VatInvoiceV2.vue'),
          children: [
            {
              path: '/',
              name: 'vat-invoice-v2',
              component: () =>
                import('@/view/components/vat-invoice/VatInvoiceListV2.vue'),
              meta: {
                title: 'Hoá đơn đỏ Chi tiết - DDV',
              },
            },
          ],
        },
        {
          path: '/lending-stock',
          component: () =>
            import('@/view/pages/holding-stocks/HoldingStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-holding-stock',
              component: () => import('@/view/pages/holding-stocks/List.vue'),
              meta: {
                title: 'Phiếu giữ hàng - DDV',
              },
            },
            {
              path: 'info-holding-stock',
              name: 'info-holding-stock',
              component: () => import('@/view/pages/holding-stocks/Info.vue'),
              meta: {
                title: 'Thông tin phiếu giữ hàng - DDV',
              },
            },
            {
              path: 'print-lending-slip',
              name: 'print-lending-slip',
              component: () => import('@/view/pages/holding-stocks/Print.vue'),
              meta: {
                title: 'Thông tin phiếu in - DDV',
              },
            },
          ],
        },
        {
          path: '/mbf-transaction',
          component: () => import('@/view/pages/mbf/MBF.vue'),
          children: [
            {
              path: '/',
              name: 'mbf-transaction',
              component: () => import('@/view/pages/mbf/MBFTransactions.vue'),
              meta: {
                title: 'Hoá đơn mbf - DDV',
              },
            },
          ],
        },
        {
          path: '/bill-convert',
          component: () => import('@/view/pages/mbf/MBF.vue'),
          children: [
            {
              path: '/',
              name: 'bill-convert',
              component: () => import('@/view/pages/bills/ListBill.vue'),
              meta: {
                title: 'Chuyển hoá đơn - DDV',
              },
              props: { billType: 7 },
            },
          ],
        },
        {
          path: '/mbf-product',
          component: () => import('@/view/pages/mbf/MBF.vue'),
          children: [
            {
              path: '/',
              name: 'mbf-product',
              component: () => import('@/view/pages/mbf/MBFProducts.vue'),
              meta: {
                title: 'Quản lí sản phẩm mbf - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-accessory',
          component: () =>
            import('@/view/pages/reward-accessories/Program.vue'),
          children: [
            {
              path: '/',
              name: 'list-reward-accessory',
              component: () =>
                import('@/view/pages/reward-accessories/List.vue'),
              meta: {
                title: 'Hoa hồng NHPK - DDV',
              },
            },
            {
              path: 'info-reward-accessory',
              name: 'info-reward-accessory',
              component: () =>
                import('@/view/pages/reward-accessories/Info.vue'),
              meta: {
                title: 'Thông tin hoa hồng NHPK - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-accessory-report',
          component: () =>
            import('@/view/pages/reward-accessories/Program.vue'),
          children: [
            {
              path: '/',
              name: 'list-reward-accessory-report',
              component: () =>
                import('@/view/pages/reward-accessories/ListReport.vue'),
              meta: {
                title: 'Hoa hồng NHPK - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-phone',
          component: () => import('@/view/pages/reward-phone/RewardPhone.vue'),
          children: [
            {
              path: '/',
              name: 'list-reward-phone',
              component: () =>
                import('@/view/pages/reward-phone/ListProgram.vue'),
              meta: {
                title: 'Hoa hồng NH máy - DDV',
              },
            },
            {
              path: 'info-reward-phone',
              name: 'info-reward-phone',
              component: () =>
                import('@/view/pages/reward-phone/ProgramInfo.vue'),
              meta: {
                title: 'Thông tin hoa hồng NH máy - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-phone-report',
          component: () => import('@/view/pages/reward-phone/RewardPhone.vue'),
          children: [
            {
              path: '/',
              name: 'list-reward-phone-report',
              component: () =>
                import('@/view/pages/reward-phone/ListReport.vue'),
              meta: {
                title: 'Báo cáo hoa hồng NH máy - DDV',
              },
            },
          ],
        },
        {
          path: '/power-bi',
          component: () => import('@/view/pages/power-bi/PowerBI.vue'),
          children: [
            {
              path: '/',
              name: 'list-power-bi',
              component: () => import('@/view/pages/power-bi/PowerBIList.vue'),
              meta: {
                title: 'Cấu hình power bi - DDV',
              },
            },
          ],
        },
        {
          path: '/development-tools',
          component: () =>
            import('@/view/pages/development-tools/DevelopmentTools.vue'),
          children: [
            {
              path: '/',
              name: 'development-tools',
              component: () =>
                import(
                  '@/view/pages/development-tools/DevelopmentToolList.vue'
                ),
              meta: {
                title: 'Cài dặt nhà phát triển',
              },
            },
          ],
        },
        {
          path: '/active-imei-report',
          component: () =>
            import('@/view/pages/imei-active-reports/ImeiActiveReport.vue'),
          children: [
            {
              path: '/',
              name: 'list-active-imei-report',
              component: () =>
                import('@/view/pages/imei-active-reports/List.vue'),
              meta: {
                title: 'Báo cáo kích hoạt IMEI - DDV',
              },
            },
          ],
        },
        {
          path: '/warranty-request',
          component: () =>
            import('@/view/pages/warranty-request/WarrantyRequest.vue'),
          children: [
            {
              path: '/',
              name: 'list-warranty-request',
              component: () =>
                import(
                  '@/view/components/warranty-request/WarrantyRequestList.vue'
                ),
              meta: {
                title: 'Danh sách phiếu bảo hành - DDV',
              },
            },
          ],
        },
        {
          path: '/warranty-bill',
          component: () =>
            import('@/view/pages/warranty-bill/WarrantyBill.vue'),
          children: [
            {
              path: '/',
              name: 'list-warranty-bill',
              component: () =>
                import('@/view/pages/warranty-bill/ListWarrantyBill.vue'),
              meta: {
                title: 'Danh sách hóa đơn bảo hành',
              },
            },
            {
              path: 'add-warranty-bill',
              name: 'add-warranty-bill',
              component: () => import('@/view/pages/bills/warranty/Add.vue'),
              meta: {
                title: 'Thêm mới đơn bảo hành từ hóa đơn',
              },
            },
            {
              path: 'transfer-warranty-stock',
              name: 'transfer-warranty-stock',
              component: () =>
                import('@/view/pages/warranty-bill/TransferWarrantyStock.vue'),
              meta: {
                title: 'Chuyển kho bảo hành',
              },
            },
          ],
        },
        {
          path: '/loyalty-notifies',
          component: () =>
            import('@/view/pages/loyalty-notify/LoyaltyNotify.vue'),
          children: [
            {
              path: '/',
              name: 'loyalty-notifies-list',
              component: () =>
                import('@/view/pages/loyalty-notify/LoyaltyNotifyList.vue'),
              meta: {
                title: 'Cấu hình thông báo Loyalty - DDV',
              },
            },
            {
              path: 'upsert',
              name: 'create-loyalty-notifies',
              component: () =>
                import('@/view/pages/loyalty-notify/LoyaltyNotifyUpsert.vue'),
              meta: {
                title: 'Tạo thông báo Loyalty - DDV',
              },
              props: true,
            },
            {
              path: 'upsert/:id',
              name: 'upsert-loyalty-notifies',
              component: () =>
                import('@/view/pages/loyalty-notify/LoyaltyNotifyUpsert.vue'),
              meta: {
                title: 'Cập nhật thông báo Loyalty - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/warranty-reason-config',
          component: () =>
            import('@/view/pages/warranty-configuration/WarrantyConfig.vue'),
          children: [
            {
              path: '/',
              name: 'warranty-config-list',
              component: () =>
                import(
                  '@/view/pages/warranty-configuration/WarrantyConfigList.vue'
                ),
              meta: {
                title: 'Cấu hình lý do bảo hành',
              },
            },
            {
              path: 'add',
              name: 'create-warranty-reason',
              component: () =>
                import(
                  '@/view/pages/warranty-configuration/AddWarrantyConfig.vue'
                ),
              meta: {
                title: 'Tạo mới lý do bảo hành',
              },
            },
            {
              path: 'update',
              name: 'update-warranty-reason',
              component: () =>
                import(
                  '@/view/pages/warranty-configuration/UpdateWarrantyConfig.vue'
                ),
              meta: {
                title: 'Cập nhật lý do bảo hành',
              },
              props: true,
            },
          ],
        },
        {
          path: '/report-inventory-summaries',
          component: () =>
            import('@/view/pages/inventory-summaries/InventorySummary.vue'),
          children: [
            {
              path: '/',
              name: 'report-inventory-summaries',
              component: () =>
                import('@/view/pages/inventory-summaries/Report.vue'),
              meta: {
                title: 'Báo cáo XNT - DDV',
              },
            },
          ],
        },
        {
          path: 'disbursement',
          component: () => import('@/view/pages/disbursement/Disbursement.vue'),
          children: [
            {
              path: '/',
              name: 'list-disbursement',
              component: () =>
                import('@/view/pages/disbursement/ListDisbursement.vue'),
              meta: {
                title: 'Danh sách giải ngân công nợ - DDV',
              },
            },
            {
              path: 'add',
              name: 'add-disbursement',
              component: () =>
                import('@/view/pages/disbursement/UpsertDisbursement.vue'),
              meta: {
                title: 'Tạo danh sách giải ngân công nợ - DDV',
              },
            },
            {
              path: 'update/:id',
              name: 'update-disbursement',
              component: () =>
                import('@/view/pages/disbursement/UpsertDisbursement.vue'),
              meta: {
                title: 'Cập nhật danh sách giải ngân công nợ - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/approval',
          component: () => import('@/view/pages/approval/Approval.vue'),
          children: [
            {
              path: '/',
              name: 'list-approval',
              component: () => import('@/view/pages/approval/List.vue'),
              meta: {
                title: 'Danh sách phiếu yêu cầu duyệt chi - DDV',
              },
            },
            {
              path: 'info',
              name: 'info-approval',
              component: () => import('@/view/pages/approval/Info.vue'),
              meta: {
                title: 'Thông tin phiếu yêu cầu duyệt chi - DDV',
              },
            },
          ],
        },
        {
          path: '/flexible-combo',
          component: () =>
            import('@/view/pages/flexible-combo/FlexibleCombo.vue'),
          children: [
            {
              path: '/',
              name: 'list-flexible-combo',
              component: () => import('@/view/pages/flexible-combo/List.vue'),
              meta: {
                title: 'Cấu hình bộ combo - DDV',
              },
            },
            {
              path: 'info-flexible-combo',
              name: 'info-flexible-combo',
              component: () => import('@/view/pages/flexible-combo/Info.vue'),
              meta: {
                title: 'Thông tin cấu hình bộ combo - DDV',
              },
            },
          ],
        },
        {
          path: '/approval-configs',
          component: () =>
            import('@/view/pages/approval-configs/ApprovalConfig.vue'),
          children: [
            {
              path: '/',
              name: 'list-approval-config',
              component: () => import('@/view/pages/approval-configs/List.vue'),
              meta: {
                title: 'Danh sách cấu hình duyệt chi - DDV',
              },
            },
            {
              path: 'info',
              name: 'info-approval-config',
              component: () => import('@/view/pages/approval-configs/Info.vue'),
              meta: {
                title: 'Thông tin cấu hình duyệt chi - DDV',
              },
            },
          ],
        },
        {
          path: '/web-partners',
          component: () => import('@/view/pages/web-partners/WebPartner.vue'),
          children: [
            {
              path: '/',
              name: 'list-web-partner',
              component: () =>
                import('@/view/pages/web-partners/WebPartnerList.vue'),
              meta: {
                title: 'Cấu hình web gateway - DDV',
              },
            },

            {
              path: 'add-web-partner',
              name: 'add-web-partner',
              component: () =>
                import('@/view/pages/web-partners/WebPartnerUpsert.vue'),
              meta: {
                title: 'Tạo cấu hình web gateway - DDV',
              },
            },
            {
              path: 'edit-web-partner/:partnerApiId',
              name: 'edit-web-partner',
              component: () =>
                import('@/view/pages/web-partners/WebPartnerUpsert.vue'),
              meta: {
                title: 'Cập nhật cấu hình web gateway - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/dealsheets',
          component: () => import('@/view/pages/dealsheets/DealSheet.vue'),
          children: [
            {
              path: '/',
              name: 'deal-sheets',
              component: () => import('@/view/pages/dealsheets/List.vue'),
              meta: {
                title: 'Danh sách DealSheet - DDV',
              },
            },
            {
              path: 'info-deal-sheet',
              name: 'info-deal-sheet',
              component: () => import('@/view/pages/dealsheets/Info.vue'),
              meta: {
                title: 'Thông Tin DealSheet - DDV',
              },
            },
          ],
        },
        {
          path: '/priority-cart',
          component: () =>
            import('@/view/pages/priority-cart/PriorityCart.vue'),
          children: [
            {
              path: '/',
              name: 'list-priority-cart',
              component: () =>
                import('@/view/pages/priority-cart/ListPriorityCart.vue'),
              meta: {
                title: 'Danh sách giỏ hàng ưu tiên - DDV',
              },
            },
            {
              path: 'upsert-priority-cart',
              name: 'upsert-priority-cart',
              component: () =>
                import('@/view/pages/priority-cart/UpsertPriorityCart.vue'),
              meta: {
                title: 'Thông tin giỏ hàng ưu tiên - DDV',
              },
            },
            {
              path: 'edit-web-partner/:partnerApiId',
              name: 'edit-web-partner',
              component: () =>
                import('@/view/pages/web-partners/WebPartnerUpsert.vue'),
              meta: {
                title: 'Cập nhật cấu hình web gateway - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/crawl-product',
          component: () =>
            import('@/view/pages/crawl-products/WebCrawlProduct.vue'),
          children: [
            {
              path: '/',
              name: 'list-product',
              component: () =>
                import('@/view/pages/crawl-products/ProductsList.vue'),
              meta: {
                title: 'Thu thập thông tin sản phẩm của các cửa hàng- DDV',
              },
            },
          ],
        },
        {
          path: 'app-credentials',
          component: () =>
            import('@/view/pages/app-credentials/AppCredentials.vue'),
          children: [
            {
              path: '/',
              name: 'list-app-credentials',
              component: () => import('@/view/pages/app-credentials/List.vue'),
              meta: {
                title: 'Danh sách Credentials - DDV',
              },
            },
            {
              path: 'add',
              name: 'add-app-credentials',
              component: () =>
                import('@/view/pages/app-credentials/Upsert.vue'),
              meta: {
                title: 'Tạo Credentials - DDV',
              },
            },
            {
              path: 'update/:id',
              name: 'update-app-credentials',
              component: () =>
                import('@/view/pages/app-credentials/Upsert.vue'),
              meta: {
                title: 'Cập nhật Credentials - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/web-partners',
          component: () => import('@/view/pages/web-partners/WebPartner.vue'),
          children: [
            {
              path: '/',
              name: 'list-web-partner',
              component: () =>
                import('@/view/pages/web-partners/WebPartnerList.vue'),
              meta: {
                title: 'Cấu hình web gateway - DDV',
              },
            },
            {
              path: 'add-web-partner',
              name: 'add-web-partner',
              component: () =>
                import('@/view/pages/web-partners/WebPartnerUpsert.vue'),
              meta: {
                title: 'Tạo cấu hình web gateway - DDV',
              },
            },
            {
              path: 'edit-web-partner/:partnerApiId',
              name: 'edit-web-partner',
              component: () =>
                import('@/view/pages/web-partners/WebPartnerUpsert.vue'),
              meta: {
                title: 'Cập nhật cấu hình web gateway - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/tax-configs',
          component: () => import('@/view/pages/tax-configs/TaxConfig.vue'),
          children: [
            {
              path: '/',
              name: 'list-tax-config',
              component: () => import('@/view/pages/tax-configs/List.vue'),
              meta: {
                title: 'Cấu hình thuế TNCN - DDV',
              },
            },
          ],
        },
        {
          path: '/salary-reports',
          component: () => import('@/view/pages/tax-configs/TaxConfig.vue'),
          children: [
            {
              path: '/',
              name: 'report-tax-config',
              component: () => import('@/view/pages/salary-reports/Report.vue'),
              meta: {
                title: 'Báo cáo bảng lương - DDV',
              },
            },
          ],
        },
        {
          path: '/allowance-configs',
          component: () =>
            import('@/view/pages/access-settings/AccessSetting.vue'),
          children: [
            {
              path: '/',
              name: 'user-access',
              component: () => import('@/view/pages/access-settings/List.vue'),
              meta: {
                title: 'Cấu hình truy cập - DDV',
              },
            },
            {
              path: 'add',
              name: 'create-user-access',
              component: () =>
                import('@/view/pages/access-settings/Upsert.vue'),
              meta: {
                title: 'Tạo Ip - DDV',
              },
            },
            {
              path: 'update/:type/:id',
              name: 'update-user-access',
              component: () =>
                import('@/view/pages/access-settings/Upsert.vue'),
              meta: {
                title: 'Cập nhật Ip - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/salary-incurred-configs',
          component: () =>
            import(
              '@/view/pages/salary-incrurred-configs/SalaryIncurredConfig.vue'
            ),
          children: [
            {
              path: '/',
              name: 'list-salary-incurred-configs',
              component: () =>
                import('@/view/pages/salary-incrurred-configs/List.vue'),
              meta: {
                title: 'Phát sinh lương - DDV',
              },
            },
          ],
        },
        {
          path: 'working-hour-config',
          component: () =>
            import('@/view/pages/app-credentials/AppCredentials.vue'),
          children: [
            {
              path: '/',
              name: 'working-hour-config',
              component: () =>
                import('@/view/pages/working-hour-config/List.vue'),
              meta: {
                title: 'Danh sách giờ công - DDV',
              },
              props: true,
            },
          ],
        },
        {
          path: '/storage-time-reports',
          component: () =>
            import('@/view/pages/storage-time-reports/StorageTimeReport.vue'),
          children: [
            {
              path: 'by-imei',
              name: 'list-imei-storage-time',
              component: () =>
                import('@/view/pages/storage-time-reports/IMEIStorageTime.vue'),
              meta: {
                title: 'Thời gian lưu kho IMEI - DDV',
              },
            },
            {
              path: 'by-cate-imei',
              name: 'storage-time-by-cate',
              component: () =>
                import(
                  '@/view/pages/storage-time-reports/IMEICateStorageTime.vue'
                ),
              meta: {
                title: 'Tuổi kho theo NH máy - DDV',
              },
            },
          ],
        },
        {
          path: '/ticket',
          component: () => import('@/view/pages/ticket/index.vue'),
          children: [
            {
              path: '/',
              name: 'list-ticket',
              component: () => import('@/view/pages/ticket/List.vue'),
              meta: {
                title: 'Danh sách phiếu yêu cầu hỗ trợ',
              },
            },
            {
              path: '/ticket-setting',
              name: 'setting-ticket',
              component: () => import('@/view/pages/ticket/Setting.vue'),
              meta: {
                title: 'Cấu hình loại phiếu',
              },
            },
          ],
        },
        {
          path: '/report-excel',
          component: () => import('@/view/pages/ticket/index.vue'),
          children: [
            {
              path: '/',
              name: 'list-report-excel',
              component: () =>
                import('@/view/pages/report-excel/ListReportExcel.vue'),
              meta: {
                title: 'Lịch sử xuất excel',
              },
            },
            {
              path: 'chart',
              name: 'chart',
              component: () =>
                import('@/view/pages/report-excel/Chart.vue'),
              meta: {
                title: 'Biểu đồ',
              },
            },
          ],
        },
        {
          path: 'telegram-chatbot-config',
          component: () =>
            import('@/view/pages/telegram-chatbot/TelegramChatbot.vue'),
          children: [
            {
              path: '/',
              name: 'telegram-chatbot-config',
              component: () => import('@/view/pages/telegram-chatbot/List.vue'),
              meta: {
                title: 'Danh sách cấu hình chatbot Telegram - DDV',
              },
            },
          ],
        },
        {
          path: 'shipping-config',
          component: () =>
            import('@/view/pages/shipping-config/ShippingConfig.vue'),
          children: [
            {
              path: '/',
              name: 'shipping-config',
              component: () => import('@/view/pages/shipping-config/List.vue'),
              meta: {
                title: 'Danh cấu hình phí ship - DDV',
              },
            },
          ],
        },
        {
          path: '/departments',
          component: () => import('@/view/pages/departments/Department.vue'),
          children: [
            {
              path: '/',
              name: 'list-department',
              component: () => import('@/view/pages/departments/List.vue'),
              meta: {
                title: 'Danh sách bộ phận - DDV',
              },
            },
          ],
        },
        {
          path: '/active-imei-config',
          component: () =>
            import('@/view/pages/active-imei-configs/ActiveImeiConfig.vue'),
          children: [
            {
              path: '/',
              name: 'list-active-imei-config',
              component: () =>
                import('@/view/pages/active-imei-configs/List.vue'),
              meta: {
                title: 'Cấu hình kích hoạt IMEI - DDV',
              },
            },
            {
              path: 'info-active-imei-config',
              name: 'info-active-imei-config',
              component: () =>
                import('@/view/pages/active-imei-configs/Info.vue'),
              meta: {
                title: 'Thông tin cấu hình kích hoạt IMEI - DDV',
              },
            },
            {
              path: 'import-active-imei-config',
              name: 'import-active-imei-config',
              component: () =>
                import('@/view/pages/active-imei-configs/ImportExcel.vue'),
              meta: {
                title: 'Import cấu hình kích hoạt IMEI - DDV',
              },
            },
          ],
        },
        {
          path: '/voucher',
          component: () => import('@/view/pages/voucher/Voucher.vue'),
          children: [
            {
              path: '/',
              name: 'list-voucher',
              component: () => import('@/view/pages/voucher/List.vue'),
              meta: {
                title: 'Danh sách voucher - DDV',
              },
            },
            {
              path: 'add-voucher',
              name: 'add-voucher',
              component: () => import('@/view/pages/voucher/Info.vue'),
              meta: {
                title: 'Thêm mới voucher - DDV',
              },
            },
            {
              path: 'update-voucher',
              name: 'update-voucher',
              component: () => import('@/view/pages/voucher/Info.vue'),
              meta: {
                title: 'Cập nhật voucher - DDV',
              },
            },
          ],
        },
        {
          path: 'access-settings',
          component: () =>
            import('@/view/pages/access-settings/AccessSetting.vue'),
          children: [
            {
              path: '/',
              name: 'user-access',
              component: () => import('@/view/pages/access-settings/List.vue'),
              meta: {
                title: 'Cấu hình truy cập - DDV',
              },
            },
            {
              path: 'add',
              name: 'create-user-access',
              component: () =>
                import('@/view/pages/access-settings/Upsert.vue'),
              meta: {
                title: 'Tạo Ip - DDV',
              },
            },
            {
              path: 'update/:type/:id',
              name: 'update-user-access',
              component: () =>
                import('@/view/pages/access-settings/Upsert.vue'),
              meta: {
                title: 'Cập nhật Ip - DDV',
              },
              props: true,
            },
          ],
        },
        ...fastSyncRoutes,
        ...businessRoutes,
      ],
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/Auth'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/RightSideLogin'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
    },
  ],
});

export default router;
